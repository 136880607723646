import { useState } from "react";
import {
  TransportFeed,
  TransportIdentifier,
  TransportInformation,
} from "../redux/types";

export const useTransportService = (transportFeeds?: TransportFeed[]) => {
  const [selectedTransportService, setSelectedTransportService] = useState<
    string | null
  >(null);

  if (!transportFeeds?.length) {
    return {
      transportInformation: undefined,
      transportIdentifiers: undefined,
      selectedTransportService,
      setSelectedTransportService,
    };
  }

  const { transportMode, isTimetableData, services } = transportFeeds[0];

  const transportInformation: TransportInformation = {
    transportMode,
    isTimetableData,
    services: selectedTransportService
      ? services.filter(({ lineName }) => lineName === selectedTransportService)
      : services,
  };

  const transportIdentifiers: TransportIdentifier[] = services.map(
    ({ lineName, lineColour }) => ({
      code: lineName,
      colour: lineColour,
    }),
  );

  return {
    transportInformation,
    transportIdentifiers,
    selectedTransportService,
    setSelectedTransportService,
  };
};
