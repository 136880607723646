import classNames from "classnames";
import React, { useContext } from "react";
import { MapContext } from "../Map/Map";
import MoveDownButton from "../MoveDownButton/MoveDownButton";

import styles from "./Footer.module.scss";
import Button from "../Button/Button";
import { Icon } from "@livingmap/core-ui-v2";

interface Props {
  controlsAlwaysLow: boolean;
  footerRef: React.RefObject<HTMLDivElement>;
  onShareToMobileClick: () => void;
}

export default function Footer({
  onShareToMobileClick,
  footerRef,
  controlsAlwaysLow,
}: Props) {
  const { controlTheme } = useContext(MapContext);

  return (
    <footer className={styles.container} data-qa="footer">
      <div className={styles.gradientContainer} />
      <div className={styles.contentContainer} ref={footerRef}>
        <div className={styles.content}>
          <div className={classNames(styles.buttons, styles.showLowerControls)}>
            <Button
              dataQA="share-to-mobile"
              onClick={onShareToMobileClick}
              label="Share to mobile"
              color="black"
              {...(controlTheme === "light" && {
                outlined: true,
              })}
              leftIcon="ShareDirectionsIcon"
              rounded
              className={classNames(
                styles.shareButton,
                styles.showLowerControls,
                { [styles.alwaysLowControls]: controlsAlwaysLow },
              )}
              theme={controlTheme}
            />
            {!controlsAlwaysLow && (
              <MoveDownButton
                color="black"
                classNames={styles.moveControlsButton}
                outlined={controlTheme === "light"}
              />
            )}
          </div>
          <div className={styles.attribution}>
            <Icon
              className={styles.logo}
              dataQA="attribution-logo"
              type="LmLogomarkIcon"
            />
            <span>&copy; Living Map</span>
            <span>&copy; OpenStreetMap</span>
          </div>
        </div>
      </div>
    </footer>
  );
}
