import { useState, useRef, useCallback, useEffect, useMemo } from "react";
import { useTransition, animated, config } from "react-spring";
import classNames from "classnames";
import type { MapOptions, LngLatBoundsLike } from "mapbox-gl";
import type LivingMap from "@livingmap/core-mapping";

import {
  Map,
  Compass,
  Header,
  CentreControl,
  FloorSelector,
  PanControl,
  SearchControl,
  Keyboard,
  ZoomControl,
  LocationButton,
  LocationStatus,
  SearchResults,
} from "../../components";
import { useSession, useImagePreloader } from "../../hooks";
import {
  ConfigurationResponse,
  InteractionEventTypes,
  Feature,
  useLazyPostRouteQuery,
  SearchTag,
} from "../../redux/services/config";
import { PLUGIN_IDS } from "../../components/Map/plugins/types";
import FloorControl from "../../components/Map/plugins/floor-control";
import PositionPlugin from "../../components/Map/plugins/position-control";
import ClusteredPinPlugin from "../../components/Map/plugins/clustered-pin-control";
import {
  createLMFeatures,
  getBoundingBox,
  throttle,
  buildRouteShortlink,
} from "../../utils";
import styles from "./BaseWithHeader.module.scss";
import IFrame from "../../components/IFrame/IFrame";
import ShareToMobileModal from "../../components/ShareToMobileModal/ShareToMobileModal";
import MoveDownView from "../../components/MoveDownView/MoveDownView";
import { useDispatch } from "react-redux";
import {
  setMoveDownPopups,
  setIsStepFreeRoute,
  setControlsAlwaysLow,
} from "../../redux/slices/applicationSlice";
import { useAppSelector } from "../../redux/hooks";
import { useOnlineMode } from "../../hooks/useOnlineMode";
import { Feature as GeoJsonFeature } from "geojson";
import RoutingPlugin from "../../components/Map/plugins/routing-control";
import { useDisplayRoute } from "../../hooks/useDisplayRoute";
import ToggleSwitch from "../../components/ToggleSwitch/ToggleSwitch";
import { ControlSizeScale, UISize, UITheme } from "../../components/types";
import StepFreeChip from "../../components/StepFreeChip/StepFreeChip";
import SearchResultsCarousel from "../../components/Map/components/SearchResultsCarousel/SearchResultsCarousel";
import {
  FeatureWithDistance,
  sortFeaturesByDistance,
} from "../../utils/sortFeaturesByDistance";
import { parseLanguageObject } from "../../utils/parseLanguageObject";
import SearchTags from "../../components/SearchTags/SearchTags";
import { MIN_CHARS, useSearchResults } from "../../hooks/useSearchResults";
import { is4kScreen } from "../../utils/is4kScreen";
import { useLazyGetTransportFeedsQuery } from "../../redux/services/transportAPI";
import SheetContainer from "../../containers/SheetContainer/SheetContainer";
import Footer from "../../components/Footer/Footer";
import MessagingProtocol from "../../components/MessagingProtocol/MessagingProtocol";
import ControlsWrapper from "../../components/ControlsWrapper/ControlsWrapper";
import { useComponentHeight } from "../../hooks/useComponentHeight";
import { useZoomLimit } from "../../hooks/useZoomLimit";
import { UIProvider } from "../../contexts/UIContext";

const DEFAULT_FEATURE_HORIZON_HEIGHT = 715;

export interface OnTouchHandlerOptions {
  featureID?: string | number;
  featureName?: string;
  searchTagID?: number;
  title?: string;
}

interface Props {
  data: ConfigurationResponse;
  preloadedFeatures?: Feature[];
  searchTags?: SearchTag[];
  uptime: string | null;
}

enum StepFreeStatus {
  SHOW = "show",
  HIDE = "hide",
  INACTIVE = "inactive",
}

const BaseWithHeader: React.FC<Props> = ({
  data,
  preloadedFeatures,
  searchTags,
  uptime,
}) => {
  const queryParams = useMemo(
    () => new URLSearchParams(window.location.search),
    [],
  );
  const routing = queryParams.get("routing") !== "disable";
  const routeFeatureId = queryParams.get("feature");

  // config data
  const {
    components: { map_primary, body, handoff_bar, top_bar, iframe },
    languages,
    stylesheet,
    location,
    server,
    floors,
    map_key: mapboxApiKey,
    screen_id,
    display,
    title,
  } = data;

  const { controls: primaryControls, search } = map_primary;
  const controlSize: UISize = primaryControls.theme.size;
  const controlTheme: UITheme = primaryControls.theme.mode;
  const mapControlMarginBottom = primaryControls.theme.margin_bottom;
  const mapControlsAlwaysLow = primaryControls.theme.always_low;

  const { enable_images, pre_load_images } = search;
  const fullHD = !is4kScreen();

  const orientation =
    display.resolution_x > display.resolution_y ? "landscape" : "portrait";

  const noticeTimeout =
    map_primary.interaction.session_timeout -
    map_primary.interaction.session_timeout_notice;

  const activeLocationStyle = useMemo(() => {
    return {
      colour: "#4B82FF", // $blue-tone-600
      borderColour: "#FFF", // $white-tone
      displayPulse: true,
    };
  }, []);

  const dispatch = useDispatch();

  const {
    moveDownPopups,
    onlineMode,
    isStepFreeRoute,
    controlsAlwaysLow,
    language,
  } = useAppSelector((state) => state.application);

  // refs
  const mapInstance = useRef<LivingMap | null>(null);
  const floorControlInstance = useRef<FloorControl | null>(null);
  const positionControlInstance = useRef<PositionPlugin | null>(null);
  const clusteredPinControlInstance = useRef<ClusteredPinPlugin | null>(null);
  const routingControlInstance = useRef<RoutingPlugin | null>(null);

  // state
  const [mapIsReady, setMapIsReady] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(false);
  const [userLocationStyle, setUserLocationStyle] =
    useState(activeLocationStyle);
  const [searchIsActive, setSearchIsActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchInputActive, setSearchInputActive] = useState(true);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [stepFreeStatus, setStepFreeStatus] = useState<StepFreeStatus>(
    StepFreeStatus.INACTIVE,
  );

  const [selectedFeature, setSelectedFeature] = useState<GeoJsonFeature | null>(
    null,
  );
  const [countdownTimeInSeconds, setCountdownTimeInSeconds] = useState(
    noticeTimeout / 1000,
  );
  const [locationBtnActive, setLocationBtnActive] = useState(true);
  const [carouselFeatures, setCarouselFeatures] = useState<
    FeatureWithDistance[]
  >([]);
  const [submittedSearchTerm, setSubmittedSearchTerm] = useState("");

  const kioskTitle = parseLanguageObject(title, language);

  const { isZoomAtLimit, resetZoomLimit, updateZoomLimit } = useZoomLimit(
    map_primary.zoom.init,
    map_primary.zoom.min,
    map_primary.zoom.max,
  );

  const {
    debouncedSearchTerm,
    results: searchResults,
    isFetching: isFetchingSearchResults,
    isTitleSearch,
  } = useSearchResults({
    features: preloadedFeatures || [],
    searchTerm,
    onSearchInputActive: setSearchInputActive,
  });

  const [routeRequestTrigger, routeResult] = useLazyPostRouteQuery();

  const [transportFeedsRequestTrigger, transportFeedsResult] =
    useLazyGetTransportFeedsQuery();

  const { renderRoute } = useDisplayRoute({
    segments: routeResult.data?.segments,
    sequenceOrder: routeResult.data?.routeMetadata[0].sequenceOrder,
    routingControl: routingControlInstance.current,
  });

  const transitions = useTransition(searchIsActive, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    reverse: searchIsActive,
    config: {
      ...config.molasses,
      duration: 200,
    },
  });

  const { checkOnlineMode } = useOnlineMode(() =>
    handleTouchEvent(
      InteractionEventTypes.OFFLINE_SESSION,
      !!map_primary?.interaction?.session_logging,
      false,
    ),
  );

  const { imagesPreloaded } = useImagePreloader(
    preloadedFeatures,
    pre_load_images,
  );

  const handleUserLocationStyle = useCallback(
    (isActive = true) => {
      setUserLocationStyle(
        isActive
          ? activeLocationStyle
          : {
              colour: "#BDBDBD", // $grey-tone-400
              borderColour: "#757575", // $grey-tone-600
              displayPulse: false,
            },
      );
    },
    [activeLocationStyle],
  );

  const handleMapRecentre = useCallback(() => {
    mapInstance.current?.getMapboxMap().easeTo({
      center: map_primary.center,
      zoom: map_primary.zoom.init,
      padding: 0,
    });

    floorControlInstance.current?.setActiveFloor(floors[map_primary.floor]);

    handleUserLocationStyle();

    resetZoomLimit();
  }, [
    floors,
    handleUserLocationStyle,
    map_primary.center,
    map_primary.floor,
    map_primary.zoom.init,
    resetZoomLimit,
  ]);

  const handleSessionTimeout = () => {
    checkOnlineMode();
    setHasInteracted(false);
    setSelectedFeature(null);
    setSubmittedSearchTerm("");
    if (!controlsAlwaysLow) dispatch(setMoveDownPopups(false));
    dispatch(setIsStepFreeRoute(false));
    setStepFreeStatus(StepFreeStatus.INACTIVE);
    setCarouselFeatures([]);

    clusteredPinControlInstance.current?.clearFeatureLabels();
    routingControlInstance.current?.clear();

    handleMapRecentre();

    if (searchIsActive) {
      handleTouchEvent(
        InteractionEventTypes.SEARCH_DIALOG_CLOSE,
        !!map_primary?.interaction?.session_logging,
        false,
        {
          event_data: searchTerm,
        },
      );

      setSearchIsActive(false);
      setSearchTerm("");
    }

    if (isShareModalOpen) setIsShareModalOpen(false);
  };

  const { handleTouchEvent, isInactive, resetTimeouts } = useSession(
    map_primary.interaction.session_timeout,
    map_primary.interaction.session_timeout_notice,
    handleSessionTimeout,
    screen_id,
  );

  const { componentRef: footerRef, height: footerHeight } =
    useComponentHeight();

  const updateRouteLinePadding = useCallback(() => {
    const paddingAllowance = fullHD ? 50 : 100;

    const paddingX =
      (fullHD
        ? ControlSizeScale[controlSize]
        : ControlSizeScale[controlSize] * 2) + paddingAllowance;
    const paddingY =
      (fullHD
        ? ControlSizeScale[controlSize]
        : ControlSizeScale[controlSize] * 2) + paddingAllowance;

    routingControlInstance.current?.setRouteLinePadding({
      top: paddingY,
      bottom:
        footerHeight * 6 -
        (iframe ? iframe.height : 0) +
        paddingY +
        (moveDownPopups ? 0 : mapControlMarginBottom) +
        (iframe ? iframe.height : 0),
      left: paddingX,
      right: paddingX,
    });
  }, [
    controlSize,
    footerHeight,
    fullHD,
    iframe,
    mapControlMarginBottom,
    moveDownPopups,
  ]);

  const handleRouteRequest = useCallback(
    (stepFree: boolean) => {
      if (!selectedFeature) return;

      // If routing is disabled or the map is in offline mode, we just want to fit the map to the selected feature
      if (!routing || !onlineMode) {
        const coords = (selectedFeature.geometry as GeoJSON.Point).coordinates;
        coords &&
          routingControlInstance.current?.handleFitMapToCoordinate(
            coords as LngLatBoundsLike,
          );
        return;
      }

      const { floor, latitude, longitude } =
        data.components.map_primary.you_marker;
      const floorPosition = Object.entries(data.floors).find(
        ([key]) => key === floor,
      );

      if (floorPosition) {
        routeRequestTrigger({
          from: {
            latitude,
            longitude,
            floorId: floorPosition[1].id,
          },
          to: {
            featureId: Number(selectedFeature.id),
          },
          project: data.project,
          ...(stepFree ? { options: { routeModifier: "step_free" } } : {}),
        });

        updateRouteLinePadding();
      }
    },
    [
      data.components.map_primary.you_marker,
      data.floors,
      data.project,
      onlineMode,
      routeRequestTrigger,
      routing,
      selectedFeature,
      updateRouteLinePadding,
    ],
  );

  const handleTransportFeedsRequest = useCallback(() => {
    // If the map is in offline mode or the selectedFeature doesn't have a transport_api property
    // or if the transportFeedsResult.originalArgs is the same as the selectedFeature.properties.transport_api
    // we don't need to make a new request
    if (
      !onlineMode ||
      !selectedFeature?.properties?.transport_api ||
      transportFeedsResult.originalArgs ===
        selectedFeature.properties.transport_api
    )
      return;

    transportFeedsRequestTrigger(selectedFeature.properties.transport_api);
  }, [
    onlineMode,
    selectedFeature,
    transportFeedsRequestTrigger,
    transportFeedsResult.originalArgs,
  ]);

  const handleSelectedFeatureRequests = useCallback(
    (stepFree: boolean) => {
      handleRouteRequest(stepFree);
      handleTransportFeedsRequest();
    },
    [handleRouteRequest, handleTransportFeedsRequest],
  );

  const handleOnTouch = useCallback(
    (eventType: InteractionEventTypes, options?: OnTouchHandlerOptions) => {
      const extraData: { [key: string]: any } = {};

      if (eventType === InteractionEventTypes.ASSET_DIALOG_CLOSE) {
        return resetTimeouts();
      }

      if (
        eventType === InteractionEventTypes.SEARCH_RESULT_OPEN &&
        options?.featureName
      ) {
        extraData.event_data = options.featureName;
      }

      if (eventType === InteractionEventTypes.SEARCH_DIALOG_CLOSE) {
        extraData.event_data = searchTerm;
      }

      if (
        (eventType === InteractionEventTypes.ASSET_DIALOG_OPEN ||
          eventType === InteractionEventTypes.SHARE_TO_MOBILE_ROUTE_VIEW) &&
        options?.featureID
      ) {
        extraData.event_data = `${options.featureID}`;
      }

      if (
        eventType === InteractionEventTypes.ROUTE_SHOWN &&
        options?.featureID
      ) {
        extraData.event_data = `${options.featureID}${
          isStepFreeRoute ? "/step-free" : ""
        }`;
      }

      if (
        eventType === InteractionEventTypes.SEARCH_TAG_TOUCH &&
        options?.searchTagID &&
        options?.title
      ) {
        extraData.event_data = {
          id: options.searchTagID,
          title: options.title,
        };
      }

      handleTouchEvent(
        eventType,
        !!map_primary?.interaction?.session_logging,
        !(
          eventType === InteractionEventTypes.LOWER_CONTROLS ||
          eventType === InteractionEventTypes.RAISE_CONTROLS ||
          eventType === InteractionEventTypes.ROUTE_SHOWN
        ),
        extraData,
      );

      if (eventType === InteractionEventTypes.RE_CENTER_MAP_TOUCH)
        handleMapRecentre();

      setHasInteracted(true);

      if (
        eventType === InteractionEventTypes.SEARCH_RESULT_OPEN ||
        eventType === InteractionEventTypes.PAN_CONTROL_TOUCH ||
        eventType === InteractionEventTypes.ASSET_DIALOG_OPEN ||
        eventType === InteractionEventTypes.ROUTE_SHOWN ||
        eventType === InteractionEventTypes.SEARCH_TAG_TOUCH
      )
        setLocationBtnActive(false);

      if (eventType !== InteractionEventTypes.LEVEL_SELECTOR_TOUCH) return;

      !selectedFeature &&
        clusteredPinControlInstance.current?.reloadFeatureLabels();

      if (
        floorControlInstance.current?.getActiveFloor()?.id !==
        floors[map_primary.you_marker.floor].id
      ) {
        handleUserLocationStyle(false);
      } else {
        handleUserLocationStyle();
      }
    },
    [
      floors,
      handleMapRecentre,
      handleTouchEvent,
      handleUserLocationStyle,
      isStepFreeRoute,
      map_primary?.interaction?.session_logging,
      map_primary.you_marker.floor,
      resetTimeouts,
      searchTerm,
      selectedFeature,
    ],
  );

  const handleKeyboardOnChange = useCallback(
    (value: string) => {
      resetTimeouts(); // Restart session timeouts
      setSearchTerm(value);
    },
    [resetTimeouts],
  );

  const handleTouchMapRecentre = () => {
    handleOnTouch(InteractionEventTypes.RE_CENTER_MAP_TOUCH);
    setLocationBtnActive(true);
  };

  const handleFeatureRouteLoad = () => {
    // If the routeFeatureId is present in the URL, we need to display the route for the given feature
    if (routeFeatureId) {
      // Find feature within the features array
      const feature = preloadedFeatures?.find(
        (feature) => feature.properties.lm_id === routeFeatureId,
      );

      if (feature) {
        const lmFeature = createLMFeatures([feature]);
        clusteredPinControlInstance.current?.updateFeatureLabels(
          lmFeature,
          true,
        );
      } else console.warn(`Feature with ID ${routeFeatureId} not found`);

      // Remove the feature query param from the URL after the route has been loaded
      queryParams.delete("feature");
      const newUrl = `${new URL(window.location.href).pathname}?${queryParams.toString()}`;
      window.history.replaceState({}, "", newUrl);
    }
  };

  const handleMapReady = (map: LivingMap) => {
    mapInstance.current = map;
    floorControlInstance.current = map.getPluginById<FloorControl>(
      PLUGIN_IDS.FLOOR,
    );
    positionControlInstance.current = map.getPluginById<PositionPlugin>(
      PLUGIN_IDS.USER_LOCATION,
    );
    clusteredPinControlInstance.current = map.getPluginById<ClusteredPinPlugin>(
      PLUGIN_IDS.CLUSTERED_PIN,
    );
    routingControlInstance.current = map.getPluginById<RoutingPlugin>(
      PLUGIN_IDS.ROUTING,
    );

    resetZoomLimit();

    handleFeatureRouteLoad();

    setMapIsReady(true);
  };

  const handleShowSearchResults = (searchTerm: string, features: Feature[]) => {
    setCarouselFeatures([]);
    setSelectedFeature(null);
    setSearchIsActive(false);
    setSubmittedSearchTerm(searchTerm);

    clusteredPinControlInstance.current?.updateFeatureLabels(
      createLMFeatures(features),
      true,
    );
    routingControlInstance.current?.clear();

    if (features.length > 1) {
      const bounds = getBoundingBox(features);
      const fitBoundsOptions: MapOptions["fitBoundsOptions"] = {
        bearing: map_primary.bearing,
        padding: 200,
      };

      mapInstance.current?.getMapboxMap().fitBounds(bounds, fitBoundsOptions);

      // HACK: This will wait for the carousel to clear before updating the carousel features
      // as it is causing issues with the carousel animation
      setTimeout(() => {
        const sortedFeatures = sortFeaturesByDistance(
          [location.longitude, location.latitude],
          features,
        );
        setCarouselFeatures(sortedFeatures);
        setSelectedFeature(sortedFeatures[0]);
      });
    } else setSearchTerm("");

    resetZoomLimit();
  };

  const handleSearchTagClick = (
    searchTagName: string,
    searchTagID: number,
    features: Feature[],
  ) => {
    handleOnTouch(InteractionEventTypes.SEARCH_TAG_TOUCH, {
      searchTagID,
      title: searchTagName,
    });

    handleShowSearchResults(searchTagName, features);
  };

  const handleSearchResultClick = (
    searchResultName: string,
    features: Feature[],
  ) => {
    // If ther are multiple features, we want to make sure that we get all the features with the same name from the
    // preloaded features as the search endpoint may not always return all the features under the same name because
    // there's a limit of 20 features getting returned from the api
    const allSearchFeatures =
      preloadedFeatures && features.length !== 1
        ? preloadedFeatures.filter(
            (feature) => feature.properties.popup_header === searchResultName,
          )
        : features;

    handleOnTouch(InteractionEventTypes.SEARCH_RESULT_OPEN, {
      featureName: searchResultName,
    });

    handleShowSearchResults(searchResultName, allSearchFeatures);
  };

  const handleSearchControlClick = () => {
    setSearchIsActive(true);
    handleOnTouch(InteractionEventTypes.SEARCH_CONTROL_TOUCH);
  };

  const handleKeyboardClose = () => {
    setSearchIsActive(false);
    handleOnTouch(InteractionEventTypes.SEARCH_DIALOG_CLOSE);
  };

  const handleZoomClick = (zoomLevel: number) => {
    handleOnTouch(InteractionEventTypes.ZOOM_CONTROL_TOUCH);
    updateZoomLimit(zoomLevel);
  };

  const handleMoveControls = () => {
    handleOnTouch(
      moveDownPopups
        ? InteractionEventTypes.RAISE_CONTROLS
        : InteractionEventTypes.LOWER_CONTROLS,
    );
  };

  const handleShareToMobileClick = () => {
    setIsShareModalOpen(true);
    handleOnTouch(
      selectedFeature
        ? InteractionEventTypes.SHARE_TO_MOBILE_ROUTE_VIEW
        : InteractionEventTypes.SHARE_TO_MOBILE_KIOSK_VIEW,
      selectedFeature ? { featureID: selectedFeature.id } : {},
    );
  };

  useEffect(() => {
    if (!selectedFeature) {
      setStepFreeStatus(StepFreeStatus.INACTIVE);
      return;
    }

    const prevArgs = routeResult.originalArgs;

    // If the selectedFeature hasn't changed, we don't need to make any requests
    if (
      !prevArgs ||
      ("featureId" in prevArgs.to &&
        prevArgs.to?.featureId !== Number(selectedFeature.id))
    ) {
      handleSelectedFeatureRequests(isStepFreeRoute);
    }
  }, [
    selectedFeature,
    handleSelectedFeatureRequests,
    isStepFreeRoute,
    routeResult.originalArgs,
  ]);

  useEffect(() => {
    if (!routing) return;

    routingControlInstance.current?.clear();

    if (!routeResult.isFetching && routeResult.isSuccess && selectedFeature) {
      const { routeHasSteps } = renderRoute();

      if (routeHasSteps || isStepFreeRoute) {
        setStepFreeStatus(StepFreeStatus.SHOW);
      } else if (!isStepFreeRoute) {
        setStepFreeStatus(StepFreeStatus.HIDE);
      }

      handleUserLocationStyle();

      floorControlInstance.current?.setActiveFloor(floors[map_primary.floor]);

      handleOnTouch(InteractionEventTypes.ROUTE_SHOWN, {
        featureID: selectedFeature?.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    floors,
    handleUserLocationStyle,
    map_primary.floor,
    renderRoute,
    routeResult.isFetching,
    routeResult.isSuccess,
    routing,
    selectedFeature,
  ]);

  useEffect(() => {
    if (routeResult.isError) setStepFreeStatus(StepFreeStatus.HIDE);
  }, [routeResult.isError]);

  useEffect(() => {
    if (selectedFeature?.id && onlineMode)
      handleOnTouch(InteractionEventTypes.ASSET_DIALOG_OPEN, {
        featureID: selectedFeature?.id,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFeature?.id]);

  useEffect(() => {
    if (!mapIsReady) return;
    if (!primaryControls.show.search) {
      return window.removeSplashScreen();
    }

    if (imagesPreloaded || !pre_load_images) {
      window.removeSplashScreen();
    }
  }, [
    mapIsReady,
    imagesPreloaded,
    primaryControls.show.search,
    pre_load_images,
  ]);

  useEffect(() => {
    if (!mapIsReady) return;

    positionControlInstance.current?.updateUserLocationStyle(
      userLocationStyle.colour,
      userLocationStyle.borderColour,
      userLocationStyle.displayPulse,
    );
  }, [
    mapIsReady,
    userLocationStyle.colour,
    userLocationStyle.borderColour,
    userLocationStyle.displayPulse,
  ]);

  useEffect(() => {
    if (countdownTimeInSeconds === 0 || !isInactive) {
      setCountdownTimeInSeconds(noticeTimeout / 1000);

      return;
    }

    const intervalID = setInterval(() => {
      setCountdownTimeInSeconds((prevCountdown) => prevCountdown - 1);
    }, 1000);

    return () => {
      clearInterval(intervalID);
    };
  }, [
    checkOnlineMode,
    countdownTimeInSeconds,
    dispatch,
    isInactive,
    noticeTimeout,
  ]);

  useEffect(() => {
    if (mapControlsAlwaysLow || orientation === "landscape") {
      dispatch(setMoveDownPopups(true));
      dispatch(setControlsAlwaysLow(true));
    }
  }, [dispatch, mapControlsAlwaysLow, orientation]);

  useEffect(() => {
    document.body.classList.add(controlTheme);
    document.documentElement.classList.add(controlSize);
  }, [controlSize, controlTheme]);

  return (
    <div
      className={styles.container}
      style={{ backgroundColor: body.theme.background_color }}
    >
      <Header
        languages={languages}
        lang={top_bar.lang!}
        theme={top_bar.theme}
        icon={top_bar.icon}
        time={server.time}
        timezone={location.timezone}
      />
      {iframe && (
        <IFrame
          dataQA="iframe-container"
          backgroundColor={
            iframe.theme.background_color ? iframe.theme.background_color : ""
          }
          height={iframe.height}
          position={iframe.position}
          url={iframe.url}
        />
      )}
      <div className={styles.primaryMap}>
        <UIProvider screenOrientation={orientation}>
          <Map
            mapID={screen_id}
            bearing={map_primary.bearing}
            zoom={map_primary.zoom.init}
            maxZoom={map_primary.zoom.max}
            minZoom={map_primary.zoom.min}
            center={map_primary.center}
            extent={map_primary.extent}
            mapStyle={stylesheet}
            floor={map_primary.floor}
            floors={floors}
            accessToken={mapboxApiKey}
            youMarker={map_primary.you_marker}
            controlTheme={controlTheme}
            controlSize={controlSize}
            interactive={!!map_primary.interaction.enabled}
            enableTouchZoom={primaryControls.show.zoom_controls}
            onTouch={handleOnTouch}
            onZoom={updateZoomLimit}
            onMapReady={handleMapReady}
            onMapDrag={() => {
              setHasInteracted(true);
              setLocationBtnActive(false);
            }}
            featureHorizonHeight={
              primaryControls.theme.feature_horizon_height
                ? primaryControls.theme.feature_horizon_height
                : fullHD
                  ? DEFAULT_FEATURE_HORIZON_HEIGHT
                  : DEFAULT_FEATURE_HORIZON_HEIGHT * 2
            }
            onFeatureSelect={setSelectedFeature}
            initZoom={map_primary.zoom.init}
            onMoveControls={handleMoveControls}
            // Pass the height of the IFrame to the map only when the IFrame is at the bottom because we don't need to adjust the floating modals when the IFrame is at the top
            iframeHeight={iframe?.position === "bottom" ? iframe?.height : 0}
            clearCarouselFeatures={() => setCarouselFeatures([])}
          >
            {preloadedFeatures &&
              transitions(
                (transitionStyles, show) =>
                  show && (
                    <MoveDownView
                      className={classNames(styles.searchWrapper, {
                        [styles.accessibleHeight]: moveDownPopups,
                      })}
                      onBgClick={handleKeyboardClose}
                    >
                      <animated.div
                        style={transitionStyles}
                        className={styles.animatedWrapper}
                      >
                        <div className={styles.searchHeightPadder}>
                          {!isFetchingSearchResults &&
                            debouncedSearchTerm === searchTerm &&
                            debouncedSearchTerm.length >= MIN_CHARS && (
                              <SearchResults
                                dataQA="search-results"
                                className={styles.searchResultsContainer}
                                results={searchResults}
                                isTitleSearch={isTitleSearch}
                                searchTerm={searchTerm.trim()}
                                onResultClick={(features) =>
                                  handleSearchResultClick(
                                    features[0].properties.popup_header,
                                    features,
                                  )
                                }
                                onScroll={throttle(resetTimeouts)}
                                enableImages={enable_images}
                                floors={floors}
                                defaultFloorId={floors[map_primary.floor].id}
                              />
                            )}
                        </div>
                        <Keyboard
                          searchTerm={
                            carouselFeatures.length ? submittedSearchTerm : ""
                          }
                          project={kioskTitle}
                          dataQA="search-keyboard"
                          onClose={handleKeyboardClose}
                          onChange={handleKeyboardOnChange}
                          enableInput={searchInputActive}
                        />
                      </animated.div>
                    </MoveDownView>
                  ),
              )}
            {isShareModalOpen && (
              <ShareToMobileModal
                qrCode={handoff_bar.qr_code}
                qrCodeLink={
                  selectedFeature
                    ? buildRouteShortlink(selectedFeature?.id, isStepFreeRoute)
                    : null
                }
                onClick={() => {
                  setIsShareModalOpen(false);
                  resetTimeouts();
                }}
              />
            )}
            {primaryControls.show.re_center_map && (
              <CentreControl
                isInactive={isInactive}
                onRecentre={handleTouchMapRecentre}
                onTouch={handleOnTouch}
                hasPanned={hasInteracted && !locationBtnActive}
                countdownTimeInSeconds={countdownTimeInSeconds}
              />
            )}
            {primaryControls.show.north_marker && (
              <div className={styles.primaryCompassContainer}>
                <Compass bearing={map_primary.bearing} />
              </div>
            )}
            <ControlsWrapper
              footerHeight={footerHeight}
              mapControlMarginBottom={mapControlMarginBottom}
              leftMapControlsContent={
                primaryControls.show.zoom_controls ||
                primaryControls.show.pan_control ? (
                  <div>
                    {primaryControls.show.pan_control && (
                      <div className={styles.controlRightMargin}>
                        <PanControl
                          onTouch={handleOnTouch}
                          hasPanned={hasInteracted}
                          setHasPanned={setHasInteracted}
                        />
                      </div>
                    )}
                    {primaryControls.show.zoom_controls && (
                      <ZoomControl
                        className={styles.zoomControlContainer}
                        minZoomReached={isZoomAtLimit.min}
                        maxZoomReached={isZoomAtLimit.max}
                        onZoomClick={handleZoomClick}
                      />
                    )}
                  </div>
                ) : null
              }
              rightMapControlsContent={
                primaryControls.show.level_selector ? (
                  <>
                    {primaryControls.show.level_selector && (
                      <FloorSelector
                        dataQA="floor-container"
                        floors={floors}
                        youAreHereFloor={map_primary.you_marker.floor}
                        onTouch={handleOnTouch}
                        poiFloorId={selectedFeature?.properties?.poi_floor_id}
                        activeFloor={
                          floorControlInstance.current?.getActiveFloor() ||
                          map_primary.floor
                        }
                      />
                    )}
                    {primaryControls.show.re_center_map && (
                      <div className={styles.controlTopMargin}>
                        <LocationButton
                          dataQA="location-button"
                          onClick={handleTouchMapRecentre}
                          status={
                            !hasInteracted || locationBtnActive
                              ? LocationStatus.FOUND
                              : LocationStatus.INACTIVE
                          }
                          theme={controlTheme}
                        />
                      </div>
                    )}
                  </>
                ) : null
              }
              searchControlsContent={
                <>
                  {onlineMode && (
                    <div
                      className={classNames(styles.toggleContainer, {
                        [styles.hidden]:
                          !selectedFeature ||
                          stepFreeStatus === StepFreeStatus.INACTIVE,
                      })}
                    >
                      <ToggleSwitch
                        className={classNames(styles.toggle, {
                          [styles.hidden]:
                            !routeResult.isSuccess ||
                            stepFreeStatus === StepFreeStatus.HIDE ||
                            routeResult.isError,
                        })}
                        dataQA="step-free-toggle"
                        label="Avoid stairs"
                        isToggled={isStepFreeRoute}
                        onToggle={() => {
                          routingControlInstance.current?.clear();
                          dispatch(setIsStepFreeRoute(!isStepFreeRoute));
                          handleSelectedFeatureRequests(!isStepFreeRoute);
                          handleOnTouch(
                            isStepFreeRoute
                              ? InteractionEventTypes.AVOID_STAIRS_OFF
                              : InteractionEventTypes.AVOID_STAIRS_ON,
                          );
                        }}
                      />
                      <StepFreeChip
                        dataQA="step-free-chip"
                        className={classNames({
                          [styles.hidden]:
                            stepFreeStatus === StepFreeStatus.SHOW,
                        })}
                        success={!routeResult.isError}
                      />
                    </div>
                  )}
                  {preloadedFeatures && primaryControls.show.search && (
                    <div className={styles.searchContainer}>
                      <SearchControl
                        dataQA="search"
                        buttonStyle="smallSquare"
                        onClick={handleSearchControlClick}
                        project={kioskTitle}
                        searchTerm={submittedSearchTerm}
                        hasInteracted={hasInteracted}
                        multipleResults={carouselFeatures.length > 1}
                        onClearClick={() => {
                          setSubmittedSearchTerm("");
                          setSelectedFeature(null);
                          setCarouselFeatures([]);
                          routingControlInstance.current?.clear();
                          clusteredPinControlInstance.current?.clearFeatureLabels();
                        }}
                      />
                    </div>
                  )}
                  {searchTags &&
                    !selectedFeature &&
                    !carouselFeatures.length && (
                      <div
                        className={classNames(styles.searchTagsContainer, {
                          [styles.accessibleHeight]: moveDownPopups,
                        })}
                      >
                        <SearchTags
                          dataQA="search-tags"
                          searchTags={searchTags}
                          onSearchTagClick={handleSearchTagClick}
                        />
                      </div>
                    )}
                  {(carouselFeatures.length > 1 || selectedFeature) && (
                    <div
                      className={classNames(styles.poiContainer, {
                        [styles.accessibleHeight]: moveDownPopups,
                      })}
                    >
                      {carouselFeatures.length > 1 ? (
                        <SearchResultsCarousel
                          dataQA="search-results-carousel"
                          features={carouselFeatures}
                          className={classNames(styles.carousel, {
                            [styles.accessibleHeight]: moveDownPopups,
                          })}
                          floors={floors}
                          defaultFloorId={floors[map_primary.floor].id}
                          selectedFeature={selectedFeature}
                          onSlideChange={(feature) => {
                            clusteredPinControlInstance.current?.updateSearchFeatureLabel(
                              feature,
                            );
                          }}
                          transportFeeds={transportFeedsResult.data}
                          transportFeedsIsError={transportFeedsResult.isError}
                        />
                      ) : (
                        <SheetContainer
                          className={classNames(styles.singlePoi, {
                            [styles.accessibleHeight]: moveDownPopups,
                          })}
                          defaultFloorId={floors[map_primary.floor].id}
                          floors={floors}
                          selectedFeature={selectedFeature}
                          onClose={() => {
                            clusteredPinControlInstance.current?.clearSelectedFeature();
                            handleOnTouch(
                              InteractionEventTypes.ASSET_DIALOG_CLOSE,
                            );
                            setSelectedFeature(null);
                            routingControlInstance.current?.clear();
                            clusteredPinControlInstance.current?.clearFeatureLabels();
                          }}
                          {...(!routeResult.isFetching && {
                            kilometres: routeResult.data?.routeMetadata.reduce(
                              (acc, curr) => acc + curr.totalLength,
                              0,
                            ),
                          })}
                          transportFeeds={transportFeedsResult.data}
                          transportFeedsIsError={transportFeedsResult.isError}
                          resetTimeouts={resetTimeouts}
                        />
                      )}
                    </div>
                  )}
                </>
              }
            />
            <Footer
              controlsAlwaysLow={controlsAlwaysLow}
              footerRef={footerRef}
              onShareToMobileClick={handleShareToMobileClick}
            />
            <MessagingProtocol
              display={display}
              timezone={location.timezone}
              uptime={uptime}
            />
          </Map>
        </UIProvider>
      </div>
    </div>
  );
};

export default BaseWithHeader;
