import { useContext, useMemo } from "react";
import classNames from "classnames";

import { Feature, Floors } from "../../../../redux/services/config";
import { SearchResultStore } from "../../../../hooks/useSearchResults";
import { getFloorDifference } from "../../../../utils/getFloorDifference";
import { getFormattedFloors } from "../../../../utils/getFormattedFloors";
import { regexify } from "../../../../utils/regexifySearchTerm";

import { MapContext } from "../../Map";
import SearchResult from "../../../SearchResult/SearchResult";
import SearchResultGroup from "../../../SearchResult/components/SearchResultGroup/SearchResultGroup";

import styles from "./SearchResults.module.scss";

export interface SearchResultsProps {
  dataQA: string;
  results: SearchResultStore;
  searchTerm: string;
  className?: string;
  enableImages: boolean;
  onResultClick: (features: Feature[]) => void;
  onScroll?: () => void;
  floors: Floors;
  defaultFloorId: number;
  isTitleSearch?: boolean;
}

// Helper function to highlight search terms
const wrapSearchTerm = (searchTerm: string, text: string): string => {
  const regex = regexify(searchTerm);
  return text.replace(regex, "<span>$&</span>").replace(/ /g, "&nbsp;");
};

const SearchResults: React.FC<SearchResultsProps> = ({
  dataQA,
  results = {},
  searchTerm,
  onResultClick,
  onScroll,
  className,
  enableImages,
  floors,
  defaultFloorId,
  isTitleSearch = false,
}) => {
  const { controlTheme } = useContext(MapContext);

  const formattedFloors = useMemo(() => getFormattedFloors(floors), [floors]);

  const hasResults = Object.keys(results).length > 0;

  return (
    <div
      data-qa={dataQA}
      className={classNames(styles.container, styles[controlTheme], className)}
      onClick={(e) => e.stopPropagation()}
    >
      {hasResults ? (
        <div className={styles.scrollContainer} onScroll={onScroll}>
          <ul className={styles.listContainer}>
            {Object.entries(results).map(([resultKey, features]) => {
              const firstFeature = features[0];
              const props = {
                title: isTitleSearch
                  ? wrapSearchTerm(
                      searchTerm,
                      firstFeature.properties.popup_header,
                    )
                  : firstFeature.properties.popup_header,
                className: styles.searchResult,
                showImage: enableImages,
                imageUrl: firstFeature.properties.popup_image_url,
                onClick: () => onResultClick(features),
                titleSearch: isTitleSearch,
              };

              // Render a group if there are multiple features
              if (features.length > 1) {
                return (
                  <SearchResultGroup
                    dataQA="search-results-group"
                    key={firstFeature.id}
                    {...props}
                  />
                );
              }

              // Render a single result
              const { amount, direction } = getFloorDifference(
                firstFeature.properties.floor_id,
                defaultFloorId,
                floors,
              );

              return (
                <SearchResult
                  dataQA="search-result"
                  key={firstFeature.id}
                  category={firstFeature.properties.popup_subheader}
                  floorName={
                    formattedFloors.find(
                      ({ id }) => id === firstFeature.properties.floor_id,
                    )?.name
                  }
                  openingTimes={firstFeature.properties.opening_times}
                  isTemporarilyClosed={
                    firstFeature.properties.is_temporarily_closed
                  }
                  floorChange={{ direction, amount }}
                  {...props}
                />
              );
            })}
          </ul>
        </div>
      ) : (
        <div className={styles.noResultsContainer}>
          <div className={styles.title}>No results</div>
          <div className={styles.paragraph}>
            Check your spelling or try another search.
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchResults;
