import classNames from "classnames";
import { FC, ReactNode, useContext } from "react";
import { format } from "date-fns";

import { MapContext } from "../Map/Map";

import NoDepartures from "../NoDepartures/NoDepartures";

import styles from "./RailAirDeparturesListCard.module.scss";

export type RailAirDeparture = {
  scheduledDepartureTime: string;
  expectedDepartureTime: string;
  destination: string;
  service: string;
  platform?: string;
};

interface Props {
  dataQA: string;
  className?: string;
  departures: RailAirDeparture[];
}

const generateExpectedDepartureTime = (
  scheduledDepartureTime: string,
  expectedDepartureTime: string,
): ReactNode => {
  const scheduled = new Date(scheduledDepartureTime);
  const expected = new Date(expectedDepartureTime);

  if (scheduled < expected) {
    return (
      <span className={styles.delayed}>
        Expected {format(expected, "HH:mm")}
      </span>
    );
  }

  return <span className={styles.onTime}>On time</span>;
};

const generateDepartureListItems = (
  departures: RailAirDeparture[],
): ReactNode[] => {
  return departures.map((departure, index) => {
    return (
      <li className={styles.departureListItem} key={index}>
        <span className={styles.departureTime}>
          {format(new Date(departure.scheduledDepartureTime), "HH:mm")}
        </span>
        <div className={styles.serviceDetails}>
          <div className={styles.destinationAndPlatform}>
            <span className={styles.departure}>{departure.destination}</span>
            <span className={styles.platform}>
              {departure.platform ? `Platform ${departure.platform}` : "—"}
            </span>
          </div>
          <div className={styles.operatorAndTime}>
            <span className={styles.operator}>{departure.service}</span>
            <span className={styles.time}>
              {generateExpectedDepartureTime(
                departure.scheduledDepartureTime,
                departure.expectedDepartureTime,
              )}
            </span>
          </div>
        </div>
      </li>
    );
  });
};

const RailAirDeparturesListCard: FC<Props> = ({
  dataQA,
  className,
  departures,
}) => {
  const { controlTheme } = useContext(MapContext);

  const departureListItems = generateDepartureListItems(departures);

  return departureListItems.length ? (
    <ul
      data-qa={dataQA}
      className={classNames(styles.container, className, styles[controlTheme])}
    >
      {departureListItems}
    </ul>
  ) : (
    <NoDepartures title="No current departures" />
  );
};

export default RailAirDeparturesListCard;
