import { useContext } from "react";
import classNames from "classnames";

import { MapContext } from "../../../../Map";
import KeyboardButton from "../KeyboardButton/KeyboardButton";
import styles from "./TextKeyboard.module.scss";

interface Props {
  handleClick: (...args: any[]) => void;
  handleArrowLeft: (...args: any[]) => void;
  handleArrowRight: (...args: any[]) => void;
  handleBackspace: (...args: any[]) => void;
}

const TextKeyboard: React.FC<Props> = ({
  handleClick,
  handleArrowLeft,
  handleArrowRight,
  handleBackspace,
}) => {
  const { controlTheme } = useContext(MapContext);

  return (
    <div className={classNames(styles.container, styles[controlTheme])}>
      <div className={styles.backdrop} />
      <div className={classNames(styles.row, styles.rowOne)}>
        <KeyboardButton
          type="text"
          text="1"
          onClick={handleClick}
          verticalAlign={false}
        />
        <KeyboardButton
          type="text"
          text="2"
          onClick={handleClick}
          verticalAlign={false}
        />
        <KeyboardButton
          type="text"
          text="3"
          onClick={handleClick}
          verticalAlign={false}
        />
        <KeyboardButton
          type="text"
          text="4"
          onClick={handleClick}
          verticalAlign={false}
        />
        <KeyboardButton
          type="text"
          text="5"
          onClick={handleClick}
          verticalAlign={false}
        />
        <KeyboardButton
          type="text"
          text="6"
          onClick={handleClick}
          verticalAlign={false}
        />
        <KeyboardButton
          type="text"
          text="7"
          onClick={handleClick}
          verticalAlign={false}
        />
        <KeyboardButton
          type="text"
          text="8"
          onClick={handleClick}
          verticalAlign={false}
        />
        <KeyboardButton
          type="text"
          text="9"
          onClick={handleClick}
          verticalAlign={false}
        />
        <KeyboardButton
          type="text"
          text="0"
          onClick={handleClick}
          verticalAlign={false}
        />
        <KeyboardButton
          dataQA="backspace"
          type="icon"
          icon={{
            type: "ShortBackspace",
          }}
          value=""
          onClick={handleBackspace}
          className={styles.backspaceKey}
        />
      </div>

      <div className={classNames(styles.row, styles.rowTwo)}>
        <KeyboardButton type="text" text="q" onClick={handleClick} />
        <KeyboardButton type="text" text="w" onClick={handleClick} />
        <KeyboardButton type="text" text="e" onClick={handleClick} />
        <KeyboardButton type="text" text="r" onClick={handleClick} />
        <KeyboardButton type="text" text="t" onClick={handleClick} />
        <KeyboardButton type="text" text="y" onClick={handleClick} />
        <KeyboardButton type="text" text="u" onClick={handleClick} />
        <KeyboardButton type="text" text="i" onClick={handleClick} />
        <KeyboardButton type="text" text="o" onClick={handleClick} />
        <KeyboardButton type="text" text="p" onClick={handleClick} />
      </div>

      <div className={classNames(styles.row, styles.rowThree)}>
        <KeyboardButton type="text" text="a" onClick={handleClick} />
        <KeyboardButton type="text" text="s" onClick={handleClick} />
        <KeyboardButton type="text" text="d" onClick={handleClick} />
        <KeyboardButton type="text" text="f" onClick={handleClick} />
        <KeyboardButton type="text" text="g" onClick={handleClick} />
        <KeyboardButton type="text" text="h" onClick={handleClick} />
        <KeyboardButton type="text" text="j" onClick={handleClick} />
        <KeyboardButton type="text" text="k" onClick={handleClick} />
        <KeyboardButton type="text" text="l" onClick={handleClick} />
      </div>

      <div className={classNames(styles.row, styles.rowFour)}>
        <KeyboardButton type="text" text="z" onClick={handleClick} />
        <KeyboardButton type="text" text="x" onClick={handleClick} />
        <KeyboardButton type="text" text="c" onClick={handleClick} />
        <KeyboardButton type="text" text="v" onClick={handleClick} />
        <KeyboardButton type="text" text="b" onClick={handleClick} />
        <KeyboardButton type="text" text="n" onClick={handleClick} />
        <KeyboardButton type="text" text="m" onClick={handleClick} />
      </div>

      <div className={classNames(styles.row, styles.rowFive)}>
        <div className={classNames(styles.column, styles.columnOne)}>
          <KeyboardButton
            dataQA="space"
            type="text"
            text=""
            onClick={() => handleClick({ currentTarget: { name: " " } })}
            className={styles.spaceKey}
            verticalAlign={false}
          />
        </div>
        <div className={classNames(styles.column, styles.columnTwo)}>
          <KeyboardButton
            dataQA="left-arrow-button"
            type="icon"
            icon={{
              type: "LeftShortArrowIcon",
            }}
            value=""
            onClick={handleArrowLeft}
            className={classNames(styles.arrowKey, styles.arrowLeftKey)}
          />
          <KeyboardButton
            dataQA="right-arrow-button"
            type="icon"
            icon={{
              type: "RightShortArrowIcon",
            }}
            value=""
            onClick={handleArrowRight}
            className={styles.arrowKey}
          />
        </div>
      </div>
    </div>
  );
};

export default TextKeyboard;
