import classNames from "classnames";
import { FC, ReactNode, useContext } from "react";

import { TransportIdentifier } from "../../redux/types";

import { generateTextColourFromBackgroundColour } from "../../utils/textColour";

import { MapContext } from "../Map/Map";
import { UIContext } from "contexts/UIContext";

import Label from "../Label/Label";
import NoDepartures from "../NoDepartures/NoDepartures";

import styles from "./TransportTimetable.module.scss";

export type TransportTimetableDeparture = {
  transportIdentifier: TransportIdentifier;
  destination: string;
  departureTime: string;
  departureTimeISO: string;
};

interface Props {
  dataQA: string;
  className?: string;
  departures: TransportTimetableDeparture[];
}

const generateDepartureListItems = (
  departures: TransportTimetableDeparture[],
): ReactNode[] =>
  departures.map((departure, index) => (
    <li className={styles.listItem} key={index}>
      <Label
        dataQA={`departure-chip-${departure.transportIdentifier.code}`}
        className={styles.label}
        text={departure.transportIdentifier.code}
        {...(departure.transportIdentifier.colour && {
          backgroundColor: departure.transportIdentifier.colour,
          color: generateTextColourFromBackgroundColour(
            departure.transportIdentifier.colour,
          ),
        })}
        active
      />
      <p className={styles.destination}>{departure.destination}</p>
      <p className={styles.departureTime}>{departure.departureTime}</p>
    </li>
  ));

const TransportTimetable: FC<Props> = ({ departures, className, dataQA }) => {
  const { controlTheme } = useContext(MapContext);
  const { screenOrientation } = useContext(UIContext);

  const departureListItems = generateDepartureListItems(departures);

  return departureListItems.length ? (
    <ul
      data-qa={dataQA}
      className={classNames(styles.container, className, styles[controlTheme], {
        [styles.landscape]: screenOrientation === "landscape",
      })}
    >
      {departureListItems}
    </ul>
  ) : (
    <NoDepartures title="No current departures" />
  );
};

export default TransportTimetable;
