import React, { useContext } from "react";
import classNames from "classnames";

import { useAppSelector } from "../../redux/hooks";

import { MapContext } from "../Map/Map";

import MoveDownButton from "../MoveDownButton/MoveDownButton";

import styles from "./MoveDownView.module.scss";

interface Props {
  className?: string;
  children: React.ReactNode;
  onBgClick?: () => void;
}

export default function MoveDownView({
  className,
  children,
  onBgClick,
}: Props) {
  const { controlTheme, featureHorizonHeight, iframeHeight } =
    useContext(MapContext);

  const { moveDownPopups, controlsAlwaysLow } = useAppSelector(
    (state) => state.application,
  );

  return (
    <div className={styles.bgContainer} onClick={onBgClick}>
      <div
        className={classNames(className, styles.container, {
          [styles.accessibleHeight]: moveDownPopups,
        })}
        {...(!moveDownPopups
          ? {
              style: {
                bottom: featureHorizonHeight - (iframeHeight || 0),
              },
            }
          : {})}
      >
        {children}
        {!controlsAlwaysLow && (
          <MoveDownButton
            color={controlTheme === "light" ? "black" : "white"}
            classNames={styles.button}
          />
        )}
      </div>
    </div>
  );
}
