import { useContext } from "react";
import { Icon } from "@livingmap/core-ui-v2";
import classNames from "classnames";

import {
  useCalculateOperatingHours,
  Status,
} from "../../hooks/useCalculateOpeningHours";

import { OperatingHours } from "../../utils";
import getDistance from "../../utils/getDistance";
import {
  FloorChange,
  getFloorDifferenceString,
} from "../../utils/getFloorDifference";

import { MapContext } from "../Map/Map";

import styles from "./FeatureInformation.module.scss";

export const wrapOpeningHours = (text: string) =>
  text.replace(/(Open|Closed)/g, "<span>$&</span>");

interface Props {
  dataQA: string;
  name: string;
  operatingHours: OperatingHours;
  category?: string | null;
  building?: string;
  floorName?: string;
  className?: string;
  isTemporarilyClosed: boolean;
  floorChange: { direction: FloorChange; amount: number };
  kilometres?: number;
}

export default function FeatureInformation({
  dataQA,
  name,
  operatingHours,
  category,
  building,
  floorName,
  className,
  isTemporarilyClosed,
  floorChange,
  kilometres,
}: Props) {
  const { controlTheme } = useContext(MapContext);

  const { openingHoursStatusCopy, status } =
    useCalculateOperatingHours(operatingHours);

  const openingHoursWrappedString = openingHoursStatusCopy
    ? wrapOpeningHours(openingHoursStatusCopy)
    : null;

  const distance = kilometres ? getDistance(kilometres) : null;

  const floorChangeString = getFloorDifferenceString(floorChange);

  return (
    <div
      className={classNames(styles.container, styles[controlTheme], className)}
      data-qa={dataQA}
    >
      <div className={styles.content}>
        <div className={styles.info}>
          <p className={styles.name}>{name}</p>
          {(category || floorName || building || operatingHours) && (
            <div className={styles.description}>
              {category && <p className={styles.subtitle}>{category}</p>}
              {(floorName || building) && (
                <p className={styles.address}>
                  {floorName && floorName}
                  {floorName && building && (
                    <span className={styles.separator}>·</span>
                  )}
                  {building && building}
                </p>
              )}
              {operatingHours && (
                <p
                  className={classNames(styles.openingTimes, {
                    [styles.closed]: status === Status.CLOSED,
                    [styles.tempClosed]: isTemporarilyClosed,
                  })}
                  dangerouslySetInnerHTML={{
                    __html: isTemporarilyClosed
                      ? Status.TEMP_CLOSED
                      : openingHoursWrappedString || "",
                  }}
                />
              )}
            </div>
          )}
        </div>
        {distance && (
          <div className={styles.distanceInfo}>
            <p>{`${floorChangeString}${distance ? `, ${distance}` : ""}`}</p>
            {floorChange.direction !== FloorChange.NONE && (
              <Icon
                dataQA="floor-change-icon"
                type="StairsIcon"
                className={classNames(styles.icon, {
                  [styles.floorDown]:
                    floorChange.direction === FloorChange.DOWN,
                })}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
