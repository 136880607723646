import { useEffect, useRef, useState } from "react";

export const useComponentHeight = () => {
  const componentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const footer = componentRef?.current;

    if (!footer) return;

    const observer = new ResizeObserver(() => {
      setHeight(footer.clientHeight);
    });

    observer.observe(footer);

    return () => {
      observer.disconnect();
    };
  }, []);

  return { componentRef, height };
};
