import classNames from "classnames";
import { Icon } from "@livingmap/core-ui-v2";
import { useContext } from "react";
import {
  Status,
  useCalculateOperatingHours,
} from "../../hooks/useCalculateOpeningHours";
import { OpeningTimes } from "../../redux/services/config";

import {
  FloorChange,
  getFloorDifferenceString,
} from "../../utils/getFloorDifference";
import getDistance from "../../utils/getDistance";

import { MapContext } from "../Map/Map";

import SearchResultImage from "./components/SearchResultImage/SearchResultImage";
import { wrapOpeningHours } from "../FeatureInformation/FeatureInformation";

import styles from "./SearchResult.module.scss";

export interface SearchResultProps {
  dataQA: string;
  category: string | null;
  className?: string;
  floorName?: string;
  imageUrl: string | null;
  onClick?: () => void;
  openingTimes: OpeningTimes;
  showImage: boolean;
  title: string;
  titleSearch: boolean;
  isTemporarilyClosed: boolean;
  floorChange: { direction: FloorChange; amount: number };
  kilometres?: number;
  building?: string;
}

export default function SearchResult({
  dataQA,
  category,
  className,
  floorName,
  imageUrl,
  onClick,
  openingTimes,
  showImage,
  title,
  titleSearch,
  isTemporarilyClosed,
  floorChange,
  kilometres,
  building,
}: SearchResultProps) {
  const { controlTheme } = useContext(MapContext);

  const { openingHoursStatusCopy, status } =
    useCalculateOperatingHours(openingTimes);

  const distance = kilometres ? getDistance(kilometres) : null;

  const openingHoursWrappedString = openingHoursStatusCopy
    ? wrapOpeningHours(openingHoursStatusCopy)
    : null;

  const floorChangeString = getFloorDifferenceString(floorChange);

  return (
    <li
      data-qa={dataQA}
      className={classNames(styles.container, styles[controlTheme], className)}
      onClick={onClick}
    >
      {showImage && <SearchResultImage imageUrl={imageUrl} />}
      <div className={styles.content}>
        <div className={styles.info}>
          <p
            className={classNames(styles.name, {
              [styles.titleSearch]: titleSearch,
            })}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          {(category || floorName || building || openingTimes) && (
            <div className={styles.description}>
              {category && <p className={styles.subtitle}>{category}</p>}
              {(floorName || building) && (
                <p className={styles.address}>
                  {floorName && floorName}
                  {floorName && building && (
                    <span className={styles.separator}>•</span>
                  )}
                  {building && building}
                </p>
              )}
              {openingTimes && (
                <p
                  className={classNames(styles.openingTimes, {
                    [styles.closed]: status === Status.CLOSED,
                    [styles.tempClosed]: isTemporarilyClosed,
                  })}
                  dangerouslySetInnerHTML={{
                    __html: isTemporarilyClosed
                      ? Status.TEMP_CLOSED
                      : openingHoursWrappedString || "",
                  }}
                />
              )}
            </div>
          )}
        </div>
        {(distance || floorChange) && (
          <div className={styles.distanceInfo}>
            {distance && <p className={styles.distance}>{distance}</p>}
            {floorChange && (
              <>
                <p className={styles.floor}>{floorChangeString}</p>
                {floorChange.direction !== FloorChange.NONE && (
                  <Icon
                    dataQA="floor-change-icon"
                    type="DownShortArrowIcon"
                    className={classNames(
                      styles.icon,
                      {
                        [styles.floorUp]:
                          floorChange.direction === FloorChange.UP,
                      },
                      {
                        [styles.floorDown]:
                          floorChange.direction === FloorChange.DOWN,
                      },
                    )}
                  />
                )}
              </>
            )}
          </div>
        )}
      </div>
    </li>
  );
}
