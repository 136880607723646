import { useContext, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { IconButton } from "@livingmap/core-ui-v2";
import { Swiper as SwiperCore } from "swiper/types";
import { Feature as GeoJsonFeature } from "geojson";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow } from "swiper/modules";

import { Floors } from "../../../../redux/services/config";
import { TransportFeed } from "../../../../redux/types";

import { getFormattedFloors } from "../../../../utils/getFormattedFloors";
import { FeatureWithDistance } from "../../../../utils/sortFeaturesByDistance";

import { MapContext } from "../../Map";
import { UIContext } from "../../../../contexts/UIContext";

import SheetContainer from "../../../../containers/SheetContainer/SheetContainer";

import "swiper/css";
import "swiper/css/effect-coverflow";
import styles from "./SearchResultsCarousel.module.scss";

export interface SearchResultsCarouselProps {
  dataQA: string;
  className?: string;
  features: FeatureWithDistance[];
  floors: Floors;
  defaultFloorId: number;
  selectedFeature?: GeoJsonFeature | null;
  onSlideChange: (feature: FeatureWithDistance) => void;
  transportFeeds?: TransportFeed[];
  transportFeedsIsError: boolean;
}

export default function SearchResultsCarousel({
  dataQA,
  className,
  features,
  floors,
  defaultFloorId,
  selectedFeature,
  onSlideChange,
  transportFeeds,
  transportFeedsIsError = false,
}: SearchResultsCarouselProps) {
  const { controlTheme } = useContext(MapContext);
  const { screenOrientation } = useContext(UIContext);

  const swiperRef = useRef<SwiperCore>();

  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const formattedFloors = getFormattedFloors(floors);

  // HACK: We need to duplicate the features if there are less than 4 features and more than 2 features because the carousel loop breaks with less than 4 slides
  const featureSlides = useMemo(
    () =>
      features.length > 3 || features.length === 2
        ? features
        : [
            ...features,
            ...features.map((feature) => ({
              ...feature,
              id: feature.id + "_copy",
            })),
          ],
    [features],
  );

  const handleSlideChange = (index: number) => {
    const activeFeatureIndex = features.findIndex(
      (feature) =>
        String(feature.id) === String(swiperRef.current?.slides[index].id) ||
        String(feature.id) ===
          String(swiperRef.current?.slides[index].id).split("_")[0],
    );

    // If the active feature is not the selected feature, call the onSlideChange callback
    if (featureSlides[activeFeatureIndex].id !== selectedFeature?.id)
      onSlideChange(features[activeFeatureIndex]);
  };

  const handleSlideClick = (index: number) => {
    if (
      activeSlideIndex === 0 &&
      index === featureSlides.length - 1 &&
      featureSlides.length > 2
    ) {
      swiperRef.current?.slidePrev();
    } else if (
      index === 0 &&
      activeSlideIndex === featureSlides.length - 1 &&
      featureSlides.length > 2
    ) {
      swiperRef.current?.slideNext();
    } else if (index > activeSlideIndex) {
      swiperRef.current?.slideNext();
    } else if (index < activeSlideIndex) {
      swiperRef.current?.slidePrev();
    }
  };

  useEffect(() => {
    const swiper = swiperRef.current;
    if (!swiper) return;

    let newIndex: number | null = null;

    // Find the index of the selected feature
    swiper.slides.forEach((slide, index) => {
      if (slide.id === String(selectedFeature?.id)) newIndex = index;
    });

    if (newIndex !== null) {
      swiper.slideTo(newIndex);

      // If the selected feature is the first or last slide, we need to destroy and recreate the loop
      // so that the carousel will create the slide before the first slide and after the last slide
      if (newIndex === 0 || newIndex === featureSlides.length - 1) {
        swiper.loopDestroy();
        swiper.loopCreate();

        // Find the new index of the selected feature after re-creating the loop
        swiper.slides.forEach((slide, index) => {
          if (slide.id === String(selectedFeature?.id)) swiper.slideTo(index);
        });
      }
    }
  }, [featureSlides.length, selectedFeature]);

  return (
    <div
      data-qa={dataQA}
      className={classNames(styles.container, styles[controlTheme], className)}
    >
      <div
        className={classNames(styles.swiperContainer, {
          [styles.landscape]: screenOrientation === "landscape",
        })}
      >
        <Swiper
          loop={features.length > 2}
          grabCursor
          slidesPerView={"auto"}
          effect="coverflow"
          centeredSlides={true}
          modules={[EffectCoverflow]}
          onBeforeInit={(swiper) => (swiperRef.current = swiper)}
          onSlideChange={(swiper) => {
            handleSlideChange(swiper.activeIndex);
            setActiveSlideIndex(swiper.realIndex);
          }}
          onSlideChangeTransitionEnd={(swiper) =>
            handleSlideChange(swiper.activeIndex)
          }
        >
          {featureSlides.map((feature, i) => {
            const selectedFeature = {
              ...feature,
              properties: {
                ...feature.properties,
                floor_name: formattedFloors.find(
                  ({ id }) => id === feature.properties?.floor_id,
                )?.name,
              },
            };

            const isLeftSlide =
              (features.length > 2 &&
                (i === activeSlideIndex - 1 ||
                  (i === featureSlides.length - 1 &&
                    activeSlideIndex === 0))) ||
              (featureSlides.length === 2 && i === 0 && activeSlideIndex === 1);

            const isRightSlide =
              (featureSlides.length > 2 &&
                (i === activeSlideIndex + 1 ||
                  (i === 0 &&
                    activeSlideIndex === featureSlides.length - 1))) ||
              (featureSlides.length === 2 && i === 1 && activeSlideIndex === 0);

            return (
              <SwiperSlide
                id={`${feature.id}`}
                key={feature.id}
                onClick={() => handleSlideClick(i)}
                className={classNames(styles.swiperSlide, {
                  [styles.inactive]: i !== activeSlideIndex,
                  // Used for the active left slide in a 2 slide carousel
                  [styles.activeLeft]:
                    activeSlideIndex === 0 && featureSlides.length === 2,
                  // Used for the active right slide in a 2 slide carousel
                  [styles.activeRight]:
                    activeSlideIndex === 1 && featureSlides.length === 2,
                  // Used for the left slide in a carousel
                  [styles.left]: isLeftSlide,
                  // Used for the right slide in a carousel
                  [styles.right]: isRightSlide,
                  [styles.landscape]: screenOrientation === "landscape",
                })}
              >
                <SheetContainer
                  showNearestLabel={i === 0}
                  showQRCode={i === activeSlideIndex || isRightSlide}
                  className={styles.searchResult}
                  selectedFeature={selectedFeature}
                  defaultFloorId={defaultFloorId}
                  floors={floors}
                  kilometres={feature.distance}
                  transportFeedsIsError={transportFeedsIsError}
                  {...(i === activeSlideIndex &&
                    transportFeeds && { transportFeeds })}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <IconButton
          dataQA="prev-slide-button"
          icon="ChevronLeftIcon"
          className={classNames(styles.swiperButton, styles.prevButton)}
          disabled={featureSlides.length === 2 && activeSlideIndex === 0}
          onClick={() => swiperRef.current?.slidePrev()}
        />
        <IconButton
          dataQA="next-slide-button"
          icon="ChevronRightIcon"
          className={classNames(styles.swiperButton, styles.nextButton)}
          disabled={featureSlides.length === 2 && activeSlideIndex === 1}
          onClick={() => swiperRef.current?.slideNext()}
        />
      </div>
    </div>
  );
}
