import mapboxgl from "mapbox-gl";
import LivingMap, {
  LayerDelegate,
  LivingMapPlugin,
  LMFeature,
  StateType,
} from "@livingmap/core-mapping";
import { Feature } from "geojson";

import { store } from "../../../store";
import { InteractionEventTypes } from "../../../redux/services/config";

import FloorControl from "./floor-control";
import RoutingPlugin from "./routing-control";
import { EMPTY_DATA_SOURCE } from "./position-control";
import { LayerIds, SourceIds } from "./types/index";
import { getFloorById } from "../../../utils";

import { OnTouchHandlerOptions } from "../../../templates/BaseWithHeader/BaseWithHeader";
import { loadIcons } from "../../../utils/loadIcons";

enum FeatureMarkerTypes {
  ACTIVE = "feature_marker_active",
  INACTIVE = "feature_marker_inactive",
  FLOOR = "feature_marker_floor",
}

class ClusteredPinPlugin extends LivingMapPlugin {
  private features: LMFeature[] = [];
  private floorPlugin: FloorControl;
  private routingPlugin: RoutingPlugin;
  private mapInstance?: mapboxgl.Map;
  private layerDelegate: LayerDelegate;
  private onTouch:
    | ((
        eventType: InteractionEventTypes,
        options?: OnTouchHandlerOptions,
      ) => void)
    | undefined;
  private onFeatureSelect: ((feature: Feature | null) => void) | undefined;
  public selectedFeature: Feature | null = null;
  private onSingleFeatureSelect: (() => void) | undefined;

  public constructor(
    id: string,
    LMMap: LivingMap,
    floorPlugin: FloorControl,
    routingPlugin: RoutingPlugin,
    onTouch?: (eventType: InteractionEventTypes) => void,
    onFeatureSelect?: (feature: Feature | null) => void,
    onSingleFeatureSelect?: () => void,
  ) {
    super(id, LMMap);
    this.LMMap = LMMap;
    this.floorPlugin = floorPlugin;
    this.routingPlugin = routingPlugin;
    this.layerDelegate = LMMap.getLayerDelegate();
    this.onTouch = onTouch;
    this.onFeatureSelect = onFeatureSelect;
    this.selectedFeature = null;
    this.onSingleFeatureSelect = onSingleFeatureSelect;
  }

  activate(): void {
    this.mapInstance = this.LMMap.getMapboxMap();

    if (
      !this.mapInstance.hasImage(FeatureMarkerTypes.ACTIVE) &&
      !this.mapInstance.hasImage(FeatureMarkerTypes.INACTIVE) &&
      !this.mapInstance.hasImage(FeatureMarkerTypes.FLOOR)
    )
      loadIcons(this.mapInstance);

    this.layerDelegate.addSource(SourceIds.ACTIVE_FLOOR_FEATURE_SOURCE_ID, {
      type: "geojson",
      data: EMPTY_DATA_SOURCE,
    });

    this.layerDelegate.addSource(SourceIds.INACTIVE_FLOOR_FEATURE_SOURCE_ID, {
      type: "geojson",
      data: EMPTY_DATA_SOURCE,
    });

    this.layerDelegate.addSource(SourceIds.SELECTED_FLOOR_FEATURE_SOURCE_ID, {
      type: "geojson",
      data: EMPTY_DATA_SOURCE,
    });

    this.layerDelegate.addLayer({
      id: LayerIds.ACTIVE_FLOOR_FEATURE_LAYER,
      type: "symbol",
      source: SourceIds.ACTIVE_FLOOR_FEATURE_SOURCE_ID,
      layout: {
        "icon-offset": [0, -25],
        "icon-allow-overlap": true,
        "icon-image": FeatureMarkerTypes.INACTIVE,
      },
    });

    this.layerDelegate.addLayer({
      id: LayerIds.INACTIVE_FLOOR_FEATURE_LAYER,
      type: "symbol",
      source: SourceIds.INACTIVE_FLOOR_FEATURE_SOURCE_ID,
      layout: {
        "icon-offset": [0, -25],
        "icon-allow-overlap": true,
        "icon-image": FeatureMarkerTypes.FLOOR,
      },
    });

    this.layerDelegate.addLayer({
      id: LayerIds.SELECTED_FLOOR_FEATURE_LAYER,
      type: "symbol",
      source: SourceIds.SELECTED_FLOOR_FEATURE_SOURCE_ID,
      layout: {
        "icon-offset": [0, -35],
        "icon-allow-overlap": true,
        "icon-image": FeatureMarkerTypes.ACTIVE,
      },
    });
  }

  public updateFeatureLabels(
    features: LMFeature[],
    updateActiveFloor?: boolean,
  ): void {
    if (!this.mapInstance) return;

    this.routingPlugin.clear();
    this.clearFeatureLabels();

    // if there's only a single feature, we want to clear the carousel otherwise clear the selected feature
    if (features.length === 1) {
      this.onSingleFeatureSelect && this.onSingleFeatureSelect();
    } else this.clearSelectedFeature();

    this.features = features;

    // Update the active floor if there's only a single feature present
    if (this.features.length === 1 && updateActiveFloor) {
      const featureFloorId = this.features[0]?.getFloorId();
      const featureFloor = featureFloorId && getFloorById(featureFloorId);
      if (featureFloor) this.floorPlugin.setActiveFloor(featureFloor);
    }

    this.renderActiveAndInactiveFeatures();
  }

  public reloadFeatureLabels(): void {
    this.updateFeatureLabels(this.features);
  }

  public clearFeatureLabels(): void {
    const activeFloorSourceProxy = this.layerDelegate.getSourceProxy(
      SourceIds.ACTIVE_FLOOR_FEATURE_SOURCE_ID,
    );
    const inactiveFloorSourceProxy = this.layerDelegate.getSourceProxy(
      SourceIds.INACTIVE_FLOOR_FEATURE_SOURCE_ID,
    );
    const selectedFloorSourceProxy = this.layerDelegate.getSourceProxy(
      SourceIds.SELECTED_FLOOR_FEATURE_SOURCE_ID,
    );

    activeFloorSourceProxy?.setData(EMPTY_DATA_SOURCE);
    inactiveFloorSourceProxy?.setData(EMPTY_DATA_SOURCE);
    selectedFloorSourceProxy?.setData(EMPTY_DATA_SOURCE);

    this.features = [];
  }

  public clearSelectedFeature(): void {
    if (this.selectedFeature?.properties) {
      this.deleteFeature(this.selectedFeature.properties.lm_id);
      this.selectedFeature = null;
      this.onFeatureSelect && this.onFeatureSelect(null);
      this.renderActiveAndInactiveFeatures();
    }
  }

  public updateSearchFeatureLabel(feature: LMFeature | Feature): void {
    let enhancedFeature = feature;
    if (feature instanceof LMFeature) {
      enhancedFeature = this.enhanceLMFeatures([feature])[0];
    }

    this.setSelectedFeature(enhancedFeature as Feature);
    this.renderActiveAndInactiveFeatures();
  }

  private renderActiveAndInactiveFeatures() {
    const currentFloor = this.floorPlugin?.getActiveFloor();
    const hasFloorData = Boolean(currentFloor);

    const featuresOnActiveFloor: LMFeature[] = [];
    const featuresOnInactiveFloors: LMFeature[] = [];

    if (this.features.length === 1) {
      featuresOnActiveFloor.push(this.features[0]);
    } else {
      for (const feature of this.features) {
        // If there is no floor data, we assume that all features are on the active floor
        if (!hasFloorData) {
          featuresOnActiveFloor.push(feature);
          continue;
        }

        const featureFloorId = feature.getFloorId();

        if (featureFloorId === currentFloor?.id)
          featuresOnActiveFloor.push(feature);
        else featuresOnInactiveFloors.push(feature);
      }
    }

    const activeMapboxFeatures: Feature[] = this.enhanceLMFeatures(
      featuresOnActiveFloor,
    );
    const inactiveMapboxFeatures: Feature[] = this.enhanceLMFeatures(
      featuresOnInactiveFloors,
    );

    for (const mapboxFeature of activeMapboxFeatures.concat(
      inactiveMapboxFeatures,
    )) {
      if (!("coordinates" in mapboxFeature.geometry)) continue;

      const lmID = mapboxFeature?.properties?.lm_id;

      if (!lmID) return;

      if (
        this.features.length === 1 ||
        this.selectedFeature?.properties?.lm_id === lmID
      ) {
        this.setSelectedFeature(mapboxFeature);
        this.onFeatureSelect && this.onFeatureSelect(mapboxFeature);
      }
    }

    const activeFloorSourceProxy = this.layerDelegate.getSourceProxy(
      SourceIds.ACTIVE_FLOOR_FEATURE_SOURCE_ID,
    );
    const inactiveFloorSourceProxy = this.layerDelegate.getSourceProxy(
      SourceIds.INACTIVE_FLOOR_FEATURE_SOURCE_ID,
    );
    const selectedFloorSourceProxy = this.layerDelegate.getSourceProxy(
      SourceIds.SELECTED_FLOOR_FEATURE_SOURCE_ID,
    );

    activeFloorSourceProxy?.setData({
      type: "FeatureCollection",
      features: activeMapboxFeatures.filter(
        (feature) => feature.id !== this.selectedFeature?.id,
      ),
    });

    inactiveFloorSourceProxy?.setData({
      type: "FeatureCollection",
      features: inactiveMapboxFeatures.filter(
        (feature) => feature.id !== this.selectedFeature?.id,
      ),
    });

    selectedFloorSourceProxy?.setData({
      type: "FeatureCollection",
      features: this.selectedFeature ? [this.selectedFeature] : [],
    });
  }

  public setSelectedFeature(feature: Feature) {
    if (!("coordinates" in feature.geometry)) return;

    const {
      application: { onlineMode },
    } = store.getState();

    this.selectedFeature = feature;

    // This is done after rendering the route instead if we're in online mode so we skip this step
    if (!onlineMode && this.onTouch)
      this.onTouch(InteractionEventTypes.ASSET_DIALOG_OPEN, {
        featureID: feature.id,
      });
  }

  private deleteFeature(id: string) {
    if (this.features.length < 1) return;

    const featureIndex = this.features.findIndex(
      (feature) => feature.getLmId() === id,
    );

    if (featureIndex === -1) return;

    this.features.splice(featureIndex, 1);
    this.reloadFeatureLabels();
  }

  private enhanceLMFeatures(LMFeatures: LMFeature[]) {
    const currentlySelectedFeature =
      this.LMMap.getFeatureStateDelegate().getFeatureForState(
        StateType.SELECTED,
      );

    return LMFeatures.map((lmFeature: LMFeature) => {
      // Throws an error about the object not being extendable, so only seems to work if it's deep-cloned
      const feature = JSON.parse(JSON.stringify(lmFeature.getMapboxFeature()));

      const featureFloorId = lmFeature.getFloorId();
      const featureFloor = featureFloorId && getFloorById(featureFloorId);

      if (featureFloor) {
        feature.properties.floor_name = featureFloor.name;
        feature.properties.floor_id = undefined; // this is needed so that the feature dots appear on all floors
        feature.properties.poi_floor_id = featureFloor.id;
      }

      if (
        currentlySelectedFeature !== null &&
        currentlySelectedFeature.getId() === lmFeature.getId()
      ) {
        feature.properties.selected = "active";
      } else {
        feature.properties.selected = "inactive";
      }

      const centroid = lmFeature.getCentroid();
      if (!centroid)
        throw new Error(
          `Centroid does not exist on LMFeature for: ${lmFeature.getId()}`,
        );

      feature.geometry = {
        type: "Point",
        coordinates: centroid,
      };

      feature.properties = {
        ...feature.properties,
        preventClickPropagation: true,
      };

      // Mapbox doesn't handle null values correctly, so remove any property that is null to allow
      // pins to be displayed correctly
      for (const k in feature.properties) {
        if (feature.properties[k] === null) {
          delete feature.properties[k];
        }
      }

      return feature;
    });
  }
}

export default ClusteredPinPlugin;
