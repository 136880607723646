import classNames from "classnames";
import { useContext } from "react";

import { MapContext } from "../Map/Map";

import styles from "./Label.module.scss";

export interface LabelProps {
  dataQA: string;
  className?: string;
  onClick?: () => void;
  color?: string;
  backgroundColor?: string;
  text: string;
  active?: boolean;
}

export default function Label({
  dataQA,
  className,
  onClick,
  color,
  backgroundColor,
  text,
  active,
}: LabelProps) {
  const { controlTheme } = useContext(MapContext);

  return (
    <div
      data-qa={dataQA}
      className={classNames(styles.container, className, styles[controlTheme], {
        [styles.inactive]: !active,
      })}
      {...(active && { style: { color, backgroundColor } })}
      onClick={onClick}
    >
      {text}
    </div>
  );
}
