import { differenceInMilliseconds, format } from "date-fns";
import groupBy from "lodash.groupby";

import { DeparturesByDestination } from "../components/DeparturesListCard/DeparturesListCard";
import { TransportTimetableDeparture } from "../components/TransportTimetable/TransportTimetable";
import { RailAirDeparture } from "../components/RailAirDeparturesListCard/RailAirDeparturesListCard";
import { TransportInformation } from "../redux/types";

export const formatDepartureTime = (departureTime: string, now: Date) => {
  const diffMillis = differenceInMilliseconds(new Date(departureTime), now);

  const minutesDifference = Math.round(diffMillis / (1000 * 60)); // convert milliseconds to minutes

  // Set to "Due" if less than 1 minute. Else check other conditions
  if (minutesDifference <= 1) return "Due";
  if (minutesDifference <= 60) return `${minutesDifference} min`;
  return format(new Date(departureTime), "HH:mm");
};

interface MetroServiceDepartures {
  code: string;
  colour: string;
  departures: DeparturesByDestination;
}

export const formatMetroDepartures = (
  transportInformation: TransportInformation | undefined,
): MetroServiceDepartures[] => {
  if (!transportInformation) return [];

  return transportInformation.services.map(
    (service): MetroServiceDepartures => {
      const departuresByDestionation: DeparturesByDestination = {};

      // Group departures by destination
      const destinationDepartures = Object.values(
        groupBy(service.departures, (departure) => departure.destination),
      );

      // For each destination's departures...
      destinationDepartures.forEach((departures) => {
        const [{ destination }] = departures;

        const formattedDepartures = departures
          .filter(({ expectedDeparturetime }, index) => {
            // Filter out departures that have an expected departure time
            // within 10 seconds of any previously iterated departures.
            // This is done by comparing the current departure time with
            // the times of all previous departures, and if a previous
            // departure time is found that's within 10 seconds of the
            // current one, the current departure is excluded.
            const departureTime = new Date(expectedDeparturetime).getTime();
            const previousDepartures = departures.slice(0, index);

            return previousDepartures.every((d) => {
              const previousDepartureTime = new Date(
                d.expectedDeparturetime,
              ).getTime();
              const timeDifference = Math.abs(
                departureTime - previousDepartureTime,
              );
              return timeDifference > 10000; // 10 seconds
            });
          })
          .map(({ expectedDeparturetime: departureTimeISO }) => {
            return { destination, departureTimeISO };
          })
          .sort((departureA, departureB) => {
            return (
              new Date(departureA.departureTimeISO).getTime() -
              new Date(departureB.departureTimeISO).getTime()
            );
          });

        departuresByDestionation[destination] = {
          departures: formattedDepartures,
        };
      });

      return {
        code: service.lineName,
        colour: service.lineColour,
        departures: departuresByDestionation,
      };
    },
  );
};

export const formatBusDepartures = (
  transportInformation: TransportInformation | undefined,
): TransportTimetableDeparture[] => {
  if (!transportInformation) return [];
  return transportInformation.services
    .reduce((acc: TransportTimetableDeparture[], service) => {
      service.departures.forEach((departure) => {
        acc.push({
          destination: departure.destination,
          departureTime: formatDepartureTime(
            departure.expectedDeparturetime,
            new Date(),
          ),
          transportIdentifier: {
            code: service.lineName,
            colour: service.lineColour,
          },
          departureTimeISO: departure.expectedDeparturetime,
        });
      });

      return acc;
    }, [])
    .sort((a, b) => {
      return (
        new Date(a.departureTimeISO).getTime() -
        new Date(b.departureTimeISO).getTime()
      );
    });
};

export const formatRailAirDepartures = (
  transportInformation: TransportInformation | undefined,
): RailAirDeparture[] => {
  if (!transportInformation) return [];
  return transportInformation.services
    .reduce((acc: RailAirDeparture[], service) => {
      service.departures.forEach((departure) => {
        acc.push({
          destination: departure.destination,
          expectedDepartureTime: departure.expectedDeparturetime,
          scheduledDepartureTime: departure.scheduledDepartureTime,
          service: service.lineName,
          platform: departure.platform,
        });
      });

      return acc;
    }, [])
    .sort((departureA, departureB) => {
      return (
        new Date(departureA.expectedDepartureTime).getTime() -
        new Date(departureB.expectedDepartureTime).getTime()
      );
    });
};
