import { TransportInformation } from "../../../../redux/types";

import {
  formatMetroDepartures,
  formatBusDepartures,
  formatRailAirDepartures,
} from "../../../../utils/formatDepartures";

import DeparturesListCard from "../../../../components/DeparturesListCard/DeparturesListCard";
import TransportTimetable from "../../../../components/TransportTimetable/TransportTimetable";
import RailAirDeparturesListCard from "../../../../components/RailAirDeparturesListCard/RailAirDeparturesListCard";

interface Props {
  transportInformation: TransportInformation;
  selectedService: string | null;
}

export default function TransportModeContainer({
  transportInformation,
  selectedService,
}: Props) {
  const { transportMode } = transportInformation;

  const departureCard =
    transportMode === "metro" ||
    transportMode === "ferry" ||
    transportMode === "tram";
  const railAirCardDeparture =
    transportMode === "rail" || transportMode === "airport";

  const metroDepartures = departureCard
    ? formatMetroDepartures(transportInformation)
    : [];
  const busDepartures =
    transportMode === "bus" ? formatBusDepartures(transportInformation) : [];
  const railAirDepartures = railAirCardDeparture
    ? formatRailAirDepartures(transportInformation)
    : [];

  return (
    <>
      {departureCard &&
        metroDepartures.map((metroService) => {
          if (selectedService && metroService.code !== selectedService)
            return null;

          return (
            <DeparturesListCard
              key={metroService.code}
              dataQA={`feature-departures-list-card-${metroService.code}`}
              transportIdentifier={{
                code: metroService.code,
                colour: metroService.colour,
              }}
              departuresByDestination={metroService.departures}
            />
          );
        })}
      {transportMode === "bus" && (
        <TransportTimetable
          dataQA="feature-transport-timetable"
          departures={
            !selectedService
              ? busDepartures
              : busDepartures.filter(
                  (busService) =>
                    busService.transportIdentifier.code === selectedService,
                )
          }
        />
      )}
      {railAirCardDeparture && (
        <RailAirDeparturesListCard
          dataQA="feature-departures-list-card-rail"
          departures={
            !selectedService
              ? railAirDepartures
              : railAirDepartures.filter(
                  (railAirService) =>
                    railAirService.service === selectedService,
                )
          }
        />
      )}
    </>
  );
}
