import { useContext } from "react";
import classNames from "classnames";
import QRCode from "react-qr-code";
import { Icon } from "@livingmap/core-ui-v2";

import useControlSizeObserver from "hooks/useControlSizeObserver";

import { scaleValue } from "../../utils";

import { UIContext } from "../../contexts/UIContext";
import { MapContext } from "../Map/Map";

import styles from "./SheetWrapper.module.scss";

interface Props {
  dataQA: string;
  qrCodeLink: string;
  className?: string;
  showQRCode?: boolean;
  children: React.ReactNode;
  onClose?: () => void;
  showNearestLabel?: boolean;
}

const SheetWrapper: React.FC<Props> = ({
  dataQA,
  qrCodeLink,
  className,
  showQRCode,
  children,
  onClose,
  showNearestLabel,
}) => {
  const { controlTheme } = useContext(MapContext);
  const { screenOrientation } = useContext(UIContext);

  const controlSize = useControlSizeObserver();

  return (
    <div
      data-qa={dataQA}
      className={classNames(styles.container, styles[controlTheme], className, {
        [styles.landscape]: screenOrientation === "landscape",
      })}
    >
      <div
        className={classNames(styles.qrCodeContainer, {
          [styles.hide]: !showQRCode,
        })}
      >
        <div className={styles.qrCodeContent}>
          <Icon
            dataQA="qr-code-icon"
            type="ShareDirectionsIcon"
            height={scaleValue(14, controlSize)}
          />
          <p>Scan the code to view on your phone</p>
          <div className={styles.qrCode}>
            <QRCode value={qrCodeLink} size={scaleValue(92, controlSize)} />
          </div>
        </div>
      </div>
      <div className={styles.contentContainer}>
        {children}
        {onClose && (
          <button onClick={onClose} className={styles.closeBtn}>
            <Icon
              dataQA="poi-close-icon"
              type="CloseIcon"
              width={scaleValue(14, controlSize)}
              height={scaleValue(14, controlSize)}
            />
          </button>
        )}
        {!onClose && showNearestLabel && (
          <div className={styles.label}>Nearest</div>
        )}
      </div>
    </div>
  );
};

export default SheetWrapper;
