import { useContext } from "react";

import { useDispatch } from "react-redux";
import { useAppSelector } from "../../redux/hooks";
import { setMoveDownPopups } from "../../redux/slices/applicationSlice";

import { MapContext } from "../Map/Map";

import Button from "../Button/Button";

interface Props {
  classNames?: string;
  color: "black" | "white";
  outlined?: boolean;
}

export default function MoveDownButton({ classNames, color, outlined }: Props) {
  const dispatch = useDispatch();

  const { onMoveControls, controlTheme } = useContext(MapContext);

  const { moveDownPopups } = useAppSelector((state) => state.application);

  return (
    <Button
      dataQA={moveDownPopups ? "move-up-button" : "move-down-button"}
      onClick={(e) => {
        e.stopPropagation();
        dispatch(setMoveDownPopups(!moveDownPopups));
        onMoveControls();
      }}
      label={moveDownPopups ? "Raise controls" : "Lower controls"}
      leftIcon={moveDownPopups ? "UpShortArrowIcon" : "DownShortArrowIcon"}
      rounded
      color={color}
      outlined={outlined}
      className={classNames}
      theme={controlTheme}
    />
  );
}
