import { Feature } from "geojson";

import { Floors } from "../../redux/services/config";
import { useAppSelector } from "../../redux/hooks";
import { TransportFeed } from "../../redux/types";

import { buildRouteShortlink, parseOpeningTimeString } from "../../utils";
import { getFloorDifference } from "../../utils/getFloorDifference";

import TransportSheet from "../../components/Map/components/TransportSheet/TransportSheet";
import AssetInformation from "../../components/Map/components/AssetInformation/AssetInformation";

interface Props {
  className?: string;
  defaultFloorId: number;
  floors: Floors;
  kilometres?: number;
  onClose?: () => void;
  selectedFeature: Feature | null;
  transportFeeds?: TransportFeed[];
  showNearestLabel?: boolean;
  showQRCode?: boolean;
  transportFeedsIsError: boolean;
  resetTimeouts?: () => void;
}
export default function SheetContainer({
  defaultFloorId,
  floors,
  selectedFeature,
  transportFeeds,
  transportFeedsIsError = false,
  showQRCode = true,
  ...props
}: Props) {
  const { isStepFreeRoute } = useAppSelector((state) => state.application);

  if (!selectedFeature) return null;

  const operatingHours = selectedFeature.properties?.opening_times || null;
  const qrCodeLink = buildRouteShortlink(selectedFeature.id, isStepFreeRoute);
  const formattedOperatingHours =
    typeof operatingHours === "string" || operatingHours === null
      ? parseOpeningTimeString(operatingHours)
      : operatingHours;
  const { amount, direction } = getFloorDifference(
    selectedFeature.properties?.poi_floor_id ||
      selectedFeature.properties?.floor_id,
    defaultFloorId,
    floors,
  );
  const featureTransportAPI = selectedFeature.properties?.transport_api;
  const transportFeedReady =
    transportFeeds &&
    featureTransportAPI &&
    transportFeeds[0].id === featureTransportAPI.split("departures/")[1];

  const sheetProps = {
    ...props,
    qrCodeLink,
    showQRCode,
    category: selectedFeature.properties?.popup_subheader,
    floorChange: { amount, direction },
    name: selectedFeature.properties?.popup_header,
  };

  return selectedFeature.properties?.transport_api ? (
    <TransportSheet
      {...sheetProps}
      dataQA="transport-information"
      {...(transportFeedReady && { transportFeeds })}
      transportFeedsIsError={transportFeedsIsError}
    />
  ) : (
    <AssetInformation
      {...sheetProps}
      dataQA="asset-information"
      building={selectedFeature.properties?.location_name}
      operatingHours={formattedOperatingHours}
      floorName={selectedFeature.properties?.floor_name}
      isTemporarilyClosed={selectedFeature.properties?.temporarily_closed}
      imageSrc={selectedFeature.properties?.popup_image_url}
    />
  );
}
