import { useContext } from "react";

import { MapContext } from "../../../Map/Map";

import styles from "./SearchResultImage.module.scss";
import classNames from "classnames";

export default function SearchResultImage({
  imageUrl,
}: {
  imageUrl: string | null;
}) {
  const { controlTheme } = useContext(MapContext);

  return (
    <div className={classNames(styles.container, styles[controlTheme])}>
      {imageUrl && (
        <img
          data-qa="search-result-image"
          className={styles.image}
          src={imageUrl}
          alt="search-result"
        />
      )}
    </div>
  );
}
