import { useContext } from "react";
import classNames from "classnames";

import { TransportIdentifier } from "../../redux/types";

import { generateTextColourFromBackgroundColour } from "../../utils/textColour";

import { MapContext } from "../Map/Map";

import Label from "../Label/Label";

import styles from "./TransportServicesList.module.scss";

interface Props {
  dataQA: string;
  className?: string;
  services: TransportIdentifier[];
  selectedService: string | null;
  handleServiceClick?: (service: string | null) => void;
}

export default function TransportServicesList({
  dataQA,
  handleServiceClick,
  selectedService,
  services,
  className,
}: Props) {
  const { controlTheme } = useContext(MapContext);

  return (
    <div
      data-qa={dataQA}
      className={classNames(styles.container, className, styles[controlTheme])}
    >
      {services.map(({ colour, code }) => {
        const serviceIsSelected = code === selectedService;

        return (
          <Label
            key={code}
            dataQA={`transport-service-${code}`}
            onClick={() => {
              if (handleServiceClick) {
                serviceIsSelected
                  ? handleServiceClick(null)
                  : handleServiceClick(code);
              }
            }}
            active={serviceIsSelected || !selectedService}
            {...(colour && {
              backgroundColor: colour,
              color: generateTextColourFromBackgroundColour(colour),
            })}
            text={code}
          />
        );
      })}
    </div>
  );
}
