import { useContext } from "react";
import classNames from "classnames";

import { MapContext } from "../../Map";

import { ReactComponent as CompassBorder } from "./assets/compass_border.svg";
import { ReactComponent as CompassText } from "./assets/compass_text.svg";

import styles from "./Compass.module.scss";

export interface CompassProps {
  bearing: number;
}

const Compass: React.FC<CompassProps> = ({ bearing }) => {
  const { controlTheme } = useContext(MapContext);

  return (
    <div className={classNames(styles.container, styles[controlTheme])}>
      <div
        style={{
          display: "flex",
          transform: `rotate(${-bearing}deg)`,
        }}
      >
        <CompassBorder className={styles.compassBorderSvg} />
      </div>
      <CompassText className={styles.compassTextSvg} />
    </div>
  );
};

export default Compass;
