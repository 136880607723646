import { useContext } from "react";
import classNames from "classnames";
import { Icon } from "@livingmap/core-ui-v2";

import { MapContext } from "../../Map";

import styles from "./ZoomControl.module.scss";

export interface ZoomControlProps {
  minZoomReached: boolean;
  maxZoomReached: boolean;
  className?: string;
  onZoomClick?: (zoomLevel: number) => void;
  horizontal?: boolean;
}

const ZoomControl: React.FC<ZoomControlProps> = ({
  minZoomReached,
  maxZoomReached,
  className,
  onZoomClick,
  horizontal,
}) => {
  const { mapInstance, controlTheme } = useContext(MapContext);

  const onZoomInClick = () => {
    if (maxZoomReached) return;

    const zoomLevel = mapInstance?.zoomOutOneLevel();
    zoomLevel && onZoomClick && onZoomClick(zoomLevel);
  };

  const onZoomOutClick = () => {
    if (minZoomReached) return;

    const zoomLevel = mapInstance?.zoomInOneLevel();
    zoomLevel && onZoomClick && onZoomClick(zoomLevel);
  };

  return (
    <div
      className={classNames(styles.container, styles[controlTheme], className, {
        [styles.vertical]: !horizontal,
      })}
    >
      <div
        data-qa="zoom-out"
        className={classNames(styles.button, {
          [styles.disabled]: minZoomReached,
        })}
        onClick={onZoomOutClick}
      >
        <Icon dataQA="zoom-out-icon" type="MinusIcon" className={styles.svg} />
      </div>
      <div
        data-qa="zoom-in"
        className={classNames(styles.button, {
          [styles.disabled]: maxZoomReached,
        })}
        onClick={onZoomInClick}
      >
        <Icon dataQA="zoom-in-icon" type="PlusIcon" className={styles.svg} />
      </div>
    </div>
  );
};

export default ZoomControl;
