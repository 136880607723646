import React, { useContext } from "react";
import classNames from "classnames";

import { InteractionEventTypes } from "../../../../redux/services/config";

import { MapContext } from "../../Map";

import Button from "../../../Button/Button";
import Modal from "../Modal/Modal";

import styles from "./CentreControl.module.scss";

export interface CentreControlProps {
  isInactive: boolean;
  inactiveText?: string;
  onRecentre: () => void;
  hasPanned: boolean;
  onTouch?: (eventType: InteractionEventTypes.SESSION_EXTENDER_TOUCH) => void;
  countdownTimeInSeconds: number;
}

const CentreControl: React.FC<CentreControlProps> = ({
  isInactive,
  inactiveText = "Are you still there?",
  onRecentre,
  onTouch,
  hasPanned,
  countdownTimeInSeconds,
}) => {
  const { controlTheme } = useContext(MapContext);

  const handleExtendSession = () =>
    onTouch && onTouch(InteractionEventTypes.SESSION_EXTENDER_TOUCH);

  return isInactive ? (
    <Modal
      dataQA="inactivity-modal"
      title={inactiveText}
      onBgClick={handleExtendSession}
    >
      <div
        className={classNames(
          styles.countDownTextContainer,
          styles[controlTheme],
        )}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.countDownText}>{countdownTimeInSeconds}</div>
      </div>
      <Button
        dataQA="continue"
        className={classNames(styles.continueButton)}
        onClick={handleExtendSession}
        rounded
        color={controlTheme === "light" ? "black" : "white"}
        label="Continue browsing"
      />
      {hasPanned && (
        <Button
          dataQA="recentre-button"
          className={classNames(styles.recentreButton, styles[controlTheme])}
          onClick={onRecentre}
          leftIcon="NearMeIcon"
          rounded
          color={controlTheme === "light" ? "white" : "black"}
          label="Recentre map"
          theme={controlTheme}
        />
      )}
    </Modal>
  ) : null;
};

export default CentreControl;
