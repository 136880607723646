import classNames from "classnames";
import { useContext } from "react";

import { useAppSelector } from "../../../../redux/hooks";

import { MapContext } from "../../Map";

import Button from "../../../Button/Button";
import MoveDownView from "../../../MoveDownView/MoveDownView";

import styles from "./Modal.module.scss";

interface Props {
  className?: string;
  dataQA: string;
  children: React.ReactNode;
  title: string;
  onBgClick?: () => void;
  onButtonClick?: () => void;
  buttonLabel?: string;
}

export default function Modal({
  className,
  dataQA,
  children,
  title,
  onBgClick,
  onButtonClick,
  buttonLabel,
}: Props) {
  const { controlTheme } = useContext(MapContext);
  const { moveDownPopups } = useAppSelector((state) => state.application);

  return (
    <MoveDownView
      className={classNames(styles.container, {
        [styles.accessibleHeight]: moveDownPopups,
      })}
      onBgClick={onBgClick}
    >
      <div
        data-qa={dataQA}
        className={classNames(styles.modal, styles[controlTheme], className)}
        onClick={(e) => e.stopPropagation()}
      >
        <p className={styles.title}>{title}</p>
        {children}
        {onButtonClick && buttonLabel && (
          <Button
            dataQA="modal-button"
            label={buttonLabel}
            onClick={onButtonClick}
            rounded
            color={controlTheme === "light" ? "black" : "white"}
          />
        )}
      </div>
    </MoveDownView>
  );
}
