import React, { createContext } from "react";

import { UIOrientation } from "../components/types";

interface UIContextProps {
  screenOrientation: UIOrientation;
}

// TODO: move other UI related context to this file as well e.g. controlTheme, controlSize, footerHeight, etc.
export const UIContext = createContext<UIContextProps>({
  screenOrientation: "portrait",
});

interface UIProviderProps extends UIContextProps {
  children: React.ReactNode;
}

export const UIProvider: React.FC<UIProviderProps> = ({
  children,
  ...props
}) => {
  return (
    <UIContext.Provider
      value={{
        ...props,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};
