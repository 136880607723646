import classNames from "classnames";
import { useCallback, useContext, useState } from "react";

import { useAppSelector } from "../../redux/hooks";

import { useMessagingProtocol } from "../../hooks/useMessagingProtocol";
import { useScreenDiagnostics } from "../../hooks/useScreenDiagnostics";

import { getScreenId } from "../../utils/getScreenId";

import { MessageType } from "../../types/messagingProtocol";

import Modal from "../Map/components/Modal/Modal";
import { MapContext } from "../Map/Map";

import styles from "./MessagingProtocol.module.scss";

interface Props {
  uptime: string | null;
  display: {
    resolution_x: number;
    resolution_y: number;
  };
  timezone: string;
}

export default function MessagingProtocol({
  display,
  timezone,
  uptime,
}: Props) {
  const { controlTheme } = useContext(MapContext);

  const { language } = useAppSelector((state) => state.application);

  const screenId = getScreenId();

  const [isScreenIdModalOpen, setIsScreenIdModalOpen] = useState(false);
  const [isDiagnosticsModalOpen, setIsDiagnosticsModalOpen] = useState(false);

  const { getScreenDiagnostics } = useScreenDiagnostics({
    timezone,
    displayResolutionX: display.resolution_x,
    displayResolutionY: display.resolution_y,
    language,
    kioskIdOverlay: isScreenIdModalOpen,
    diagnosticsOverlay: isDiagnosticsModalOpen,
    uptime,
  });

  const handleMessagingProtocol = useCallback(
    (messageType: MessageType) => {
      switch (messageType) {
        case MessageType.RELOAD_WEBVIEW:
          return window.location.reload();
        case MessageType.OVERLAY_KIOSK_ID_SHOW:
          return !isScreenIdModalOpen && setIsScreenIdModalOpen(true);
        case MessageType.OVERLAY_KIOSK_ID_HIDE:
          return isScreenIdModalOpen && setIsScreenIdModalOpen(false);
        case MessageType.OVERLAY_DIAGNOSTICS_SHOW:
          return !isDiagnosticsModalOpen && setIsDiagnosticsModalOpen(true);
        case MessageType.OVERLAY_DIAGNOSTICS_HIDE:
          return isDiagnosticsModalOpen && setIsDiagnosticsModalOpen(false);
        default:
          break;
      }
    },
    [isDiagnosticsModalOpen, isScreenIdModalOpen],
  );

  useMessagingProtocol(screenId, handleMessagingProtocol, getScreenDiagnostics);

  return (
    <>
      {isScreenIdModalOpen && (
        <Modal
          dataQA="screen-id-modal"
          title="Kiosk ID"
          onBgClick={() => setIsScreenIdModalOpen(false)}
          onButtonClick={() => setIsScreenIdModalOpen(false)}
          buttonLabel="Close"
        >
          <div className={classNames(styles[controlTheme], styles.modalBody)}>
            {screenId}
          </div>
        </Modal>
      )}
      {isDiagnosticsModalOpen && (
        <Modal
          dataQA="diagnostics-modal"
          title="Diagnostics"
          onBgClick={() => setIsDiagnosticsModalOpen(false)}
          onButtonClick={() => setIsDiagnosticsModalOpen(false)}
          buttonLabel="Close"
        >
          <div
            className={classNames(
              styles[controlTheme],
              styles.modalBody,
              styles.diagnosticsBody,
            )}
          >
            {JSON.stringify(getScreenDiagnostics(), null, "\t")}
          </div>
        </Modal>
      )}
    </>
  );
}
