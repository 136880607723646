import classNames from "classnames";
import { ReactNode } from "react";

import { useAppSelector } from "../../redux/hooks";

import styles from "./ControlsWrapper.module.scss";

interface Props {
  footerHeight: number;
  mapControlMarginBottom: number;
  leftMapControlsContent?: ReactNode | null;
  rightMapControlsContent?: ReactNode | null;
  searchControlsContent: ReactNode;
}

export default function ControlsWrapper({
  footerHeight,
  mapControlMarginBottom,
  leftMapControlsContent,
  rightMapControlsContent,
  searchControlsContent,
}: Props) {
  const { moveDownPopups } = useAppSelector((state) => state.application);

  return (
    <>
      {/* Map controls */}
      <div
        className={styles.mapControlsContainer}
        style={{
          bottom: `${
            moveDownPopups ? 0 : footerHeight + mapControlMarginBottom
          }px`,
        }}
      >
        {/* Left map controls */}
        {leftMapControlsContent && (
          <div
            className={classNames(
              styles.controlContainer,
              styles.controlLeftContainer,
              { [styles.accessibleHeight]: moveDownPopups },
            )}
          >
            {leftMapControlsContent}
          </div>
        )}
        {/* Right map controls */}
        {rightMapControlsContent && (
          <div
            className={classNames(
              styles.controlContainer,
              styles.controlRightContainer,
              { [styles.accessibleHeight]: moveDownPopups },
            )}
          >
            {rightMapControlsContent}
          </div>
        )}
      </div>
      {/* Search controls */}
      <div
        className={styles.searchControlsContainer}
        style={{
          bottom: `${
            moveDownPopups
              ? footerHeight
              : footerHeight + mapControlMarginBottom
          }px`,
        }}
      >
        <div
          className={classNames(styles.searchFeaturesContainer, {
            [styles.accessibleHeight]: moveDownPopups,
          })}
        >
          {searchControlsContent}
        </div>
      </div>
    </>
  );
}
