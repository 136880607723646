import { useContext } from "react";
import QRCode from "react-qr-code";

import { MapContext } from "../Map/Map";

import { Modal } from "../../components";

import styles from "./ShareToMobileModal.module.scss";

export interface ShareToMobileModalProps {
  qrCode: {
    svg_base64: string;
  };
  qrCodeLink?: string | null;
  onClick: () => void;
}

export default function ShareToMobileModal({
  qrCode,
  qrCodeLink,
  onClick,
}: ShareToMobileModalProps) {
  const { controlTheme } = useContext(MapContext);

  return (
    <Modal
      dataQA="share-to-mobile-modal"
      title="Share to mobile"
      className={styles[controlTheme]}
      onBgClick={onClick}
      buttonLabel="Done"
      onButtonClick={onClick}
    >
      {qrCodeLink ? (
        <QRCode value={qrCodeLink} className={styles.qrCode} />
      ) : (
        <img
          data-qa="qr-code"
          src={`data:image/svg+xml;base64,${qrCode.svg_base64}`}
          alt="QR Code"
          className={styles.qrCode}
        />
      )}
      <p className={styles.description}>Scan this with your phone</p>
    </Modal>
  );
}
