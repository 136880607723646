import { useContext } from "react";
import classNames from "classnames";

import { OperatingHours } from "../../../../utils";
import { FloorChange } from "../../../../utils/getFloorDifference";

import { MapContext } from "../../Map";

import FeatureInformation from "../../../FeatureInformation/FeatureInformation";
import SheetWrapper from "../../../SheetWrapper/SheetWrapper";

import styles from "./AssetInformation.module.scss";

export interface AssetInformationProps {
  dataQA: string;
  name: string;
  operatingHours: OperatingHours;
  qrCodeLink: string;
  category?: string;
  building?: string;
  floorName?: string;
  className?: string;
  isTemporarilyClosed: boolean;
  onClose?: () => void;
  imageSrc?: string;
  floorChange: { direction: FloorChange; amount: number };
  kilometres?: number;
  showNearestLabel?: boolean;
  showQRCode?: boolean;
}

const AssetInformation: React.FC<AssetInformationProps> = ({
  name,
  operatingHours,
  category,
  building,
  floorName,
  isTemporarilyClosed,
  imageSrc,
  floorChange,
  kilometres,
  ...props
}) => {
  const { controlTheme } = useContext(MapContext);

  return (
    <SheetWrapper {...props}>
      <div className={classNames(styles.container, styles[controlTheme])}>
        {imageSrc && (
          <img
            data-qa="asset-background"
            src={imageSrc}
            alt="asset-background"
            className={styles.image}
          />
        )}
        <FeatureInformation
          dataQA="feature-information"
          {...{
            name,
            category,
            floorName,
            building,
            operatingHours,
            isTemporarilyClosed,
            floorChange,
            kilometres,
            imageSrc,
          }}
        />
      </div>
    </SheetWrapper>
  );
};

export default AssetInformation;
