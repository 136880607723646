import { useState } from "react";

export const useZoomLimit = (
  initZoom: number,
  minZoom: number,
  maxZoom: number,
) => {
  const getZoomLimitState = (zoom: number) => ({
    min: zoom <= minZoom,
    max: zoom >= maxZoom,
  });

  const [isZoomAtLimit, setIsZoomAtLimit] = useState(() =>
    getZoomLimitState(initZoom),
  );

  const resetZoomLimit = () => {
    setIsZoomAtLimit(getZoomLimitState(initZoom));
  };

  const updateZoomLimit = (zoomLevel: number) => {
    setIsZoomAtLimit(getZoomLimitState(zoomLevel));
  };

  return { isZoomAtLimit, resetZoomLimit, updateZoomLimit };
};
