import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { UITheme, UISize } from "./../../components/types";

export enum InteractionEventTypes {
  MAP_TOUCH = "map_touch",
  LEVEL_SELECTOR_TOUCH = "level_selector_touch",
  RE_CENTER_MAP_TOUCH = "re_center_map_touch",
  PAN_CONTROL_TOUCH = "pan_control_touch",
  ZOOM_CONTROL_TOUCH = "zoom_control_touch",
  SESSION_EXTENDER_TOUCH = "session_extender_touch",
  SEARCH_CONTROL_TOUCH = "search_control_touch",
  SEARCH_RESULT_OPEN = "search_result_open",
  SEARCH_DIALOG_CLOSE = "search_dialog_close",
  ASSET_DIALOG_OPEN = "asset_dialog_open",
  ASSET_DIALOG_CLOSE = "asset_dialog_close",
  LOWER_CONTROLS = "lower_controls",
  RAISE_CONTROLS = "raise_controls",
  SHARE_TO_MOBILE_ROUTE_VIEW = "share_to_mobile_route_view",
  SHARE_TO_MOBILE_KIOSK_VIEW = "share_to_mobile_kiosk_view",
  AVOID_STAIRS_ON = "avoid_stairs_on",
  AVOID_STAIRS_OFF = "avoid_stairs_off",
  OFFLINE_SESSION = "offline_session",
  ROUTE_SHOWN = "route_shown",
  SEARCH_TAG_TOUCH = "search_tag_touch",
}

export interface Theme {
  mode?: UITheme;
  size?: UISize;
  background_color?: string;
  font_family?: string;
  text_color?: string;
  heading_color?: string;
  link_color?: string;
}

export interface Lang {
  [key: string]: {
    heading?: string;
    subheading?: string;
    time_format?: string | null;
    call_to_action?: string;
    scan_qr?: string;
    use_link?: string;
    text?: string;
  };
}

interface ConfigurationComponent {
  [key: string]: {
    theme: Theme;
    lang?: Lang;
    [key: string]: any;
  };
}

export interface Floors {
  [key: string]: {
    id: number;
    floor: string;
    name: string;
    short_name: string;
  };
}

export enum Day {
  MONDAY = "monday",
  TUESDAY = "tuesday",
  WEDNESDAY = "wednesday",
  THURSDAY = "thursday",
  FRIDAY = "friday",
  SATURDAY = "saturday",
  SUNDAY = "sunday",
}

export type DayInfo = {
  opening_time: string;
  closing_time: string;
} | null;

export type OpeningTimes = {
  [key in Day]: DayInfo;
};

export interface Feature {
  type: "Feature";
  geometry: GeoJSON.Geometry;
  id: number;
  properties: {
    lm_id: string;
    floor_id: number;
    name: string;
    popup_header: string;
    popup_image_url: string | null;
    street_address: string | null;
    category: string;
    geom_type: string;
    opening_times: OpeningTimes;
    is_temporarily_closed: boolean;
    popup_subheader: string | null;
    transport_api: string | null;
  };
  layer: {
    id: string;
    type: string;
  };
  source: string;
  sourceLayer: string;
}

export type LanguageObject = {
  lang: string;
  text: string;
};

export interface ConfigurationResponse {
  components: ConfigurationComponent;
  display: {
    resolution_x: number;
    resolution_y: number;
  };
  languages: string[];
  location: {
    bearing: number;
    floor: number;
    latitude: number;
    longitude: number;
    timezone: string;
  };
  project: string;
  screen_id: string;
  screen_name: string;
  server: {
    commit: string;
    env: string;
    time: string;
    version: string;
  };
  stylesheet: string;
  template: string;
  floors: Floors;
  map_key: string;
  title: LanguageObject[];
}

interface HeartbeatPingResponse {
  type: "created" | "rate_limited";
  code: 201 | 429 | 503;
  message: string;
}

interface HeartbeatPingRequestBody {
  id: string;
  up_since: string;
  screen: {
    width: number;
    height: number;
  };
  frontend: {
    version: string;
    commit: string;
  };
}

export interface EventType {
  timestamp: string;
  event_type: InteractionEventTypes;
}

export interface SessionDataRequestBody {
  id: string;
  session_start: string;
  session_end: string;
  component: string;
  events: EventType[];
}

export interface SessionDataResponse {
  type: string;
  code: number;
  message: string;
}

interface FeatureListResponse {
  object: string;
  url: string;
  data: Feature[];
}

export interface SearchTag {
  id: number;
  name: LanguageObject[];
  features: Feature[];
}

interface SearchTagsListResponse {
  object: string;
  url: string;
  data: SearchTag[];
}

interface SessionData30DaysResponse {
  sessions_date: string;
  sessions_day: Day;
  sessions_count: number;
}

export type RouteLocation =
  | {
      latitude: number;
      longitude: number;
      floorId: number;
    }
  | { featureId: number };

export interface RouteOptions {
  routeModifier?: string;
  speed?: number;
}

export interface RouteMilestoneFeature {
  type: "Feature";
  geometry: {
    type: "Point";
    coordinates: [number, number];
  };
  properties: {
    descriptionText: string;
    floorId?: number;
    originId?: number;
    mapIcon: string;
    type: string;
    direction?: string;
    method?: string;
  };
}

export interface RouteQueryResponse {
  routeMetadata: {
    name: string;
    totalTime: number;
    totalLength: number;
    sequenceOrder: number[];
  }[];
  segments: {
    id?: number;
    type: "internal" | "external";
    length: number;
    time: number;
    wait?: number;
    vehicle: "walking" | "car" | "bus" | "bike" | "train";
    routeGeoJson: {
      type: "Feature";
      geometry: {
        type: "LineString";
        coordinates: [number, number][];
      };
      properties: {
        floorId: number;
        section: string;
        sequence: number;
        costTime: number;
        length: number;
      };
    }[];
    originalDescription?: string;
    destinationDescription?: string;
    metaData?: {
      routeDescription: string;
      providerAttribution: string;
    };
    routeMilestones?: {
      type: "FeatureCollection";
      features: RouteMilestoneFeature[];
    };
    dynamicGeofences?: {
      type: "FeatureCollection";
      features: {
        type: "Feature";
        geometry: {
          type: "Polygon";
          coordinates?: [number, number][];
        };
        properties: {
          floorId: number;
          geofenceType: "floor_change";
          activationCount: number;
          destinationFloorId: number;
        };
      }[];
    };
  }[];
}

export const configApi = createApi({
  reducerPath: "configApi",
  tagTypes: ["Route", "SearchResults"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  endpoints: (builder) => ({
    getConfig: builder.query<ConfigurationResponse, string>({
      query: (id) => `/${id}`,
    }),
    getFeatureList: builder.query<FeatureListResponse, string>({
      query: (id) => `/${id}/features`,
    }),
    getSearchTagsList: builder.query<SearchTagsListResponse, string>({
      query: (id) => `/${id}/searchtags`,
    }),
    getFeaturesBySearch: builder.query<
      FeatureListResponse,
      {
        id: string;
        query: string;
      }
    >({
      query: ({ id, query }) => ({
        url: `/${id}/search`,
        params: { query },
      }),
      providesTags: ["SearchResults"],
    }),
    heartbeatPing: builder.mutation<
      HeartbeatPingResponse,
      HeartbeatPingRequestBody
    >({
      query: ({ id, up_since, screen, frontend }) => ({
        url: `/${id}/ping`,
        method: "POST",
        body: { up_since, screen, frontend },
      }),
    }),
    postSessionData: builder.mutation<
      SessionDataResponse,
      SessionDataRequestBody
    >({
      query: ({ id, ...requestBody }) => ({
        url: `/${id}/session`,
        method: "POST",
        body: requestBody,
      }),
    }),
    getSessionData30Days: builder.query<SessionData30DaysResponse[], string>({
      query: (id) => `/${id}/session/count/30days`,
    }),
    postRoute: builder.query<
      RouteQueryResponse,
      {
        from: RouteLocation;
        to: RouteLocation;
        via?: RouteLocation[];
        options?: RouteOptions;
        project: string;
      }
    >({
      query: ({ from, to, via, options, project }) => ({
        url: `${process.env.REACT_APP_ROUTING_URL}/route`,
        method: "POST",
        body: { from, to, via, options, project },
      }),
      providesTags: ["Route"],
    }),
  }),
});

export const {
  useGetConfigQuery,
  useGetFeatureListQuery,
  useGetSearchTagsListQuery,
  useGetFeaturesBySearchQuery,
  useHeartbeatPingMutation,
  usePostSessionDataMutation,
  useGetSessionData30DaysQuery,
  useLazyPostRouteQuery,
} = configApi;
