import { Icon } from "@livingmap/core-ui-v2";
import classNames from "classnames";
import { useContext } from "react";
import { MapContext } from "../Map/Map";

import styles from "./ToggleSwitch.module.scss";

export interface ToggleSwitchProps {
  className?: string;
  dataQA: string;
  isDisabled?: boolean;
  isToggled: boolean;
  label: string;
  onToggle: () => void;
}

export default function ToggleSwitch({
  className,
  dataQA,
  isDisabled,
  isToggled,
  label,
  onToggle,
}: ToggleSwitchProps) {
  const { controlTheme } = useContext(MapContext);

  return (
    <div
      className={classNames(className, styles.container, styles[controlTheme])}
      data-qa={dataQA}
      onClick={(e) => {
        if (!isDisabled) {
          e.preventDefault();
          onToggle();
        }
      }}
    >
      <label className={styles.label} htmlFor="toggleSwitch">
        <input
          type="checkbox"
          className={styles.input}
          name="toggleSwitch"
          id="toggleSwitch"
          checked={isToggled}
          disabled={isDisabled}
          readOnly
        />
        <span className={styles.slider}>
          <span className={styles.sliderToggle}>
            {isToggled && (
              <Icon type="CheckSmIcon" dataQA="toggle-check-icon" />
            )}
          </span>
        </span>
      </label>
      <span className={styles.textLabel}>{label}</span>
    </div>
  );
}
