import React, { useContext } from "react";
import classNames from "classnames";

import { MapContext } from "../Map/Map";

import styles from "./NoDepartures.module.scss";

interface Props {
  title: string;
}

const NoDepartures: React.FC<Props> = ({ title }) => {
  const { controlTheme } = useContext(MapContext);

  return (
    <div className={classNames(styles.container, styles[controlTheme])}>
      <p>{title}</p>
    </div>
  );
};

export default NoDepartures;
