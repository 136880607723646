export type UITheme = "light" | "dark";
export type UISize = "small" | "medium" | "large";
export type UIOrientation = "landscape" | "portrait";

export interface UIStyles {
  theme?: UITheme;
  size?: UISize;
}

export enum ControlSizeScale {
  small = 0.8,
  medium = 1,
  large = 1.2,
}

export const FullHdResolution = { x: 1080, y: 1920 } as const;
export const FourKResolution = { x: 2160, y: 3840 } as const;
