import { Icon } from "@livingmap/core-ui-v2";
import classNames from "classnames";
import { useContext } from "react";

import { MapContext } from "../Map/Map";

import styles from "./StepFreeChip.module.scss";

export interface StepFreeChipProps {
  className?: string;
  dataQA: string;
  success: boolean;
}

export default function StepFreeChip({
  className,
  dataQA,
  success,
}: StepFreeChipProps) {
  const { controlTheme } = useContext(MapContext);

  return (
    <div
      data-qa={dataQA}
      className={classNames(className, styles.container, styles[controlTheme])}
    >
      <span
        className={classNames(styles.icon, {
          [styles.check]: success,
          [styles.error]: !success,
        })}
      >
        <Icon
          type={success ? "CheckSolidIcon" : "ErrorSolidIcon"}
          dataQA="step-free-chip-icon"
        />
      </span>
      <span className={styles.textLabel}>
        {success ? "Step-free route" : "No step-free route available"}
      </span>
    </div>
  );
}
