import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { TransportFeed } from "../types";

export const transportAPI = createApi({
  reducerPath: "transportAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_TRANSPORT_URL,
  }),
  endpoints: (builder) => ({
    getTransportFeeds: builder.query<
      TransportFeed[],
      string | null | undefined
    >({
      query: (transportString) => `/${transportString}`,
    }),
  }),
});

export const { useLazyGetTransportFeedsQuery } = transportAPI;

export default transportAPI;
