import { useState, useEffect } from "react";

import { UISize } from "components/types";

export default function useControlSizeObserver(): UISize {
  const [controlSize, setControlSize] = useState([
    ...document.documentElement.classList,
  ]);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      setControlSize([...document.documentElement.classList]);
    });

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ["class"],
    });

    return () => observer.disconnect();
  }, []);

  if (controlSize.includes("large")) return "large";
  else if (controlSize.includes("small")) return "small";
  else return "medium";
}
