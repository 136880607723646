import { FC, ReactNode, useContext } from "react";
import classNames from "classnames";

import { TransportIdentifier } from "../../redux/types";

import { generateTextColourFromBackgroundColour } from "../../utils/textColour";
import { formatDepartureTime } from "../../utils/formatDepartures";

import { MapContext } from "../Map/Map";

import styles from "./DeparturesListCard.module.scss";

type Departure = {
  destination: string;
  departureTimeISO: string;
};

export type DeparturesByDestination = {
  [destination: string]: {
    departures: Departure[];
  };
};

interface Props {
  dataQA: string;
  className?: string;
  transportIdentifier: TransportIdentifier;
  departuresByDestination: DeparturesByDestination;
  onClick?: (transportIdentifier: TransportIdentifier) => void;
}

const formatSubsequentDepartureTimes = ([firstTime, secondTime]: string[]) => {
  if (firstTime?.includes("min") && secondTime?.includes("min")) {
    return `${firstTime.replace(" min", "")}, ${secondTime}`;
  } else if (firstTime?.length && secondTime?.length) {
    return `${firstTime}, ${secondTime}`;
  } else if (firstTime?.length) {
    return firstTime;
  }
};

const generateDepartureListItems = (
  departuresByDestination: DeparturesByDestination,
): ReactNode[] => {
  const now = new Date();
  return Object.values(departuresByDestination)
    .sort(
      ({ departures: departuresA }, { departures: departuresB }) =>
        // Step 1: Sort destinations by earliest departure time
        new Date(departuresA[0].departureTimeISO).getTime() -
        new Date(departuresB[0].departureTimeISO).getTime(),
    )
    .map((departures, index) => {
      // Step 2: Sort departures by time
      const sortedDepartures = departures.departures.sort((a, b) => {
        return (
          new Date(a.departureTimeISO).getTime() -
          new Date(b.departureTimeISO).getTime()
        );
      });

      // Step 3: Process the sorted departures to get the time difference in minutes
      const departureTimes = sortedDepartures.map((departure) => {
        return formatDepartureTime(departure.departureTimeISO, now);
      });

      const formattedSubsequentDepartureTimes = formatSubsequentDepartureTimes(
        departureTimes.slice(1),
      );

      const destination = departures.departures[0].destination;

      return (
        <li className={styles.destination} key={index}>
          <div className={styles.destinationInfo}>
            <span className={styles.destinationName}>{destination}</span>
          </div>
          <div className={styles.departureTimes}>
            <span className={styles.nextDeparture}>{departureTimes[0]}</span>
            {formattedSubsequentDepartureTimes && (
              <span className={styles.futureDepartures}>
                then {formattedSubsequentDepartureTimes}
              </span>
            )}
          </div>
        </li>
      );
    });
};

const DeparturesListCard: FC<Props> = ({
  dataQA,
  className,
  transportIdentifier,
  departuresByDestination,
  onClick,
}) => {
  const { controlTheme } = useContext(MapContext);

  // Generate the departure list items
  const departureListItems = generateDepartureListItems(
    departuresByDestination,
  );

  return (
    <div
      data-qa={dataQA}
      className={classNames(styles.container, className, styles[controlTheme])}
      onClick={() => onClick && onClick(transportIdentifier)}
    >
      <div
        className={styles.header}
        style={{
          background: transportIdentifier.colour,
          color: generateTextColourFromBackgroundColour(
            transportIdentifier.colour,
          ),
        }}
      >
        {transportIdentifier.code}
      </div>
      <ul className={styles.departuresList}>
        {departureListItems.length === 0 ? (
          <li className={styles.noUpcomingDepartures}>No current departures</li>
        ) : (
          departureListItems
        )}
      </ul>
    </div>
  );
};

export default DeparturesListCard;
