import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { configApi } from "./redux/services/config";
import { messagingProtocolApi } from "./redux/services/messagingProtocol";

import applicationSlice from "./redux/slices/applicationSlice";
import transportAPI from "./redux/services/transportAPI";

export const store = configureStore({
  reducer: {
    application: applicationSlice,
    [configApi.reducerPath]: configApi.reducer,
    [messagingProtocolApi.reducerPath]: messagingProtocolApi.reducer,
    [transportAPI.reducerPath]: transportAPI.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      configApi.middleware,
      messagingProtocolApi.middleware,
      transportAPI.middleware,
    ),
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
