import classNames from "classnames";
import { useContext } from "react";
import { Icon } from "@livingmap/core-ui-v2";

import { TransportFeed } from "../../../../redux/types";

import {
  FloorChange,
  getFloorDifferenceString,
} from "../../../../utils/getFloorDifference";
import getDistance from "../../../../utils/getDistance";
import { throttle } from "../../../../utils";

import { useTransportService } from "../../../../hooks/useTransportService";

import { MapContext } from "../../Map";

import TransportModeContainer from "../../../../containers/TransportModeContainer/TransportModeContainer/TransportModeContainer/TransportModeContainer";

import SheetWrapper from "../../../SheetWrapper/SheetWrapper";
import NoDepartures from "../../../NoDepartures/NoDepartures";
import TransportServicesList from "../../../TransportServicesList/TransportServicesList";

import styles from "./TransportSheet.module.scss";

export interface TransportSheetProps {
  dataQA: string;
  className?: string;
  qrCodeLink: string;
  showQRCode?: boolean;
  name: string;
  category: string;
  kilometres?: number;
  floorChange: { direction: FloorChange; amount: number };
  showNearestLabel?: boolean;
  onClose?: () => void;
  transportFeeds?: TransportFeed[];
  transportFeedsIsError: boolean;
  resetTimeouts?: () => void;
}

export default function TransportSheet({
  name,
  category,
  floorChange,
  kilometres,
  onClose,
  transportFeeds,
  transportFeedsIsError = false,
  resetTimeouts,
  ...props
}: TransportSheetProps) {
  const { controlTheme } = useContext(MapContext);

  const distance = kilometres ? getDistance(kilometres) : null;

  const floorChangeString = getFloorDifferenceString(floorChange);

  const {
    selectedTransportService,
    setSelectedTransportService,
    transportIdentifiers,
    transportInformation,
  } = useTransportService(transportFeeds);

  const handleServiceClick = (service: string | null) => {
    resetTimeouts && resetTimeouts();
    setSelectedTransportService(service);
  };

  return (
    <SheetWrapper {...props} onClose={onClose}>
      <div className={classNames(styles.container, styles[controlTheme])}>
        <div
          className={styles.infoContainer}
          {...(resetTimeouts && { onScroll: throttle(resetTimeouts) })}
        >
          <div className={styles.info}>
            <p className={styles.title}>{name}</p>
            <div className={styles.details}>
              <p className={styles.subtitle}>{category}</p>
              {(distance || floorChange) && (
                <div className={styles.distanceInfo}>
                  <p>
                    {`${floorChangeString}${distance ? `, ${distance}` : ""}`}
                  </p>
                  {floorChange.direction !== FloorChange.NONE && (
                    <Icon
                      dataQA="floor-change-icon"
                      type="StairsIcon"
                      className={classNames(styles.icon, {
                        [styles.floorDown]:
                          floorChange.direction === FloorChange.DOWN,
                      })}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
          {transportIdentifiers && transportIdentifiers.length > 0 && (
            <TransportServicesList
              dataQA="transport-services-list"
              className={styles.routes}
              services={transportIdentifiers}
              selectedService={selectedTransportService}
              {...(onClose && {
                handleServiceClick,
              })}
            />
          )}
        </div>
        {onClose && (transportInformation || transportFeedsIsError) && (
          <div
            className={styles.timetableContainer}
            {...(resetTimeouts && { onScroll: throttle(resetTimeouts) })}
          >
            {transportFeedsIsError ? (
              <NoDepartures title="Times unavailable right now" />
            ) : transportInformation?.services.length ? (
              <TransportModeContainer
                selectedService={selectedTransportService}
                transportInformation={transportInformation}
              />
            ) : (
              <NoDepartures title="No current departures" />
            )}
          </div>
        )}
      </div>
    </SheetWrapper>
  );
}
